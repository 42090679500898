/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'

import { CarbonCommonAgGridCellRendererProps } from 'components/table/control/CarbonAgGridTypes'

interface CarbonMoreLabelCellComponentProps extends CarbonCommonAgGridCellRendererProps {
  value: string | string[]
  lineCount: number
}

const CarbonMoreLabelCellComponent: React.FC<CarbonMoreLabelCellComponentProps> = ({
  node,
  column,
  value,
  eGridCell,
  lineCount
}) => {
  const ref = useRef<HTMLSpanElement>(null)
  const divRef = useRef<HTMLDivElement>(null)
  // const [isShowMore, setIsShowMore] = useState(false)

  // useEffect(() => {
  //   const resizeObserver = new ResizeObserver((entries) => {
  //     for (const entry of entries) {
  //       if (entry.target === divRef.current && ref.current) {
  //         const spanHeight = ref.current.offsetHeight
  //         if (spanHeight > 20 * lineCount) {
  //           setIsShowMore(true)
  //         } else {
  //           setIsShowMore(false)
  //         }
  //       }
  //     }
  //   })
  //   if (divRef.current) {
  //     resizeObserver.observe(divRef.current)
  //   }

  //   return () => {
  //     resizeObserver.disconnect()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const getValue = () => {
    if (Array.isArray(value)) {
      return value.join('<br>')
    }
    return value
  }

  return (
    <div className="carbon-more-label-cell" ref={divRef}>
      <div className="carbon-more-label-cell-text" style={{ WebkitLineClamp: lineCount }}>
        {/* {isShowMore && (
          <span className="carbon-more-label-cell-more" style={{ height: `${lineCount * 20}px` }}>
            더보기
          </span>
        )} */}
        <span
          dangerouslySetInnerHTML={{ __html: getValue().toString().replace(/\n/g, '<br>') }}
          ref={ref}
        />
      </div>
    </div>
  )
}

export default CarbonMoreLabelCellComponent

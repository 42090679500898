import { CustomHeaderProps } from 'ag-grid-react'
import React from 'react'

const EssentialAgGridHeaderComponent: React.FC<CustomHeaderProps> = ({ api, column }) => {
  return (
    <div className="carbon-table-custom-header-frame">
      {column.getColDef().headerName}
      <sup style={{ color: 'red' }}>*</sup>
    </div>
  )
}

export default EssentialAgGridHeaderComponent

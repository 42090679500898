/* eslint-disable no-unused-vars */
import { Column, RowNode } from 'ag-grid-community'
import classNames from 'classnames'
import React from 'react'

import { CarbonAgGridLabelType } from 'components/table/column/CarbonColumnDefTypes'
import { CarbonCommonAgGridCellRendererProps } from 'components/table/control/CarbonAgGridTypes'
import { convertAmountToThousandSeparatedAmountStr, isMinusAmount } from 'utils/amount_utils'
import { isEmpty, notEmpty } from 'utils/common_utils'

export enum LabelTextAlign {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center'
}

export enum LabelTextVerticalAlign {
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom'
}

interface CarbonLabelAgGridCellRendererComponentProps extends CarbonCommonAgGridCellRendererProps {
  value: string
  placeholder?: string | ((node: RowNode, column: Column) => string | undefined)
  convertValueCallbackFunc?: (value: string, node?: RowNode) => string
  isTextOverflowEllipsis?: boolean
  appendPostfixCallbackFunc?: (value: string, node?: RowNode) => string
  labelType?: CarbonAgGridLabelType
  labelTextAlign?: LabelTextAlign
  labelTextVerticalAlign?: LabelTextVerticalAlign
}

const LabelAgGridCellRendererComponent: React.FC<CarbonLabelAgGridCellRendererComponentProps> = ({
  node,
  column,
  value,
  placeholder,
  convertValueCallbackFunc,
  eGridCell,
  isTextOverflowEllipsis = false,
  appendPostfixCallbackFunc,
  labelType = CarbonAgGridLabelType.Basic,
  labelTextAlign = LabelTextAlign.LEFT,
  labelTextVerticalAlign = LabelTextVerticalAlign.MIDDLE
}) => {
  // related placeholder
  const getPlaceholderText = () => {
    if (typeof placeholder === 'function') {
      return placeholder(node, column) ?? ''
    }

    return placeholder
  }
  const placeholderText = getPlaceholderText()

  // related value
  const getBaseValue = () => {
    // value가 number인 경우 string으로 변환(number가 들어와버릴 수 있음)
    if (value === undefined || value === null) {
      return ''
    }
    const valueStr = value.toString()

    switch (labelType) {
      case CarbonAgGridLabelType.Amount:
        return convertAmountToThousandSeparatedAmountStr(valueStr)
      case CarbonAgGridLabelType.AmountWithoutHandleMinus:
        return convertAmountToThousandSeparatedAmountStr(valueStr, false)
      default:
        return valueStr
    }
  }
  const baseValue = getBaseValue()

  // related class names
  const getLabelClassNames = () => {
    const labelClasses = [
      'carbon-table-renderer-common',
      'carbon-table-label',
      `carbon-table-label-align-${labelTextAlign}`,
      `carbon-table-label-vertical-align-${labelTextVerticalAlign}`
    ]

    switch (labelType) {
      case CarbonAgGridLabelType.Amount: {
        labelClasses.push('carbon-table-label-amount')
        if (isMinusAmount(baseValue)) {
          labelClasses.push('carbon-table-label-amount-minus')
        }
        break
      }
      case CarbonAgGridLabelType.AmountWithoutHandleMinus: {
        labelClasses.push('carbon-table-label-amount')
        break
      }
      default:
        break
    }

    return classNames(labelClasses)
  }
  const labelClassNames = getLabelClassNames()

  const needToRenderPlaceholder = isEmpty(baseValue) && notEmpty(placeholderText)

  const renderPlaceholderCell = () => {
    return (
      <div className={labelClassNames} style={{ width: eGridCell.clientWidth }}>
        <span className="carbon-table-label-placeholder">{placeholderText}</span>
      </div>
    )
  }

  const renderValueCell = () => {
    if (labelType === CarbonAgGridLabelType.Link) {
      if (notEmpty(baseValue)) {
        return (
          <a href={`https://${baseValue}`} rel="noopener noreferrer" target="_blank">
            {baseValue}
          </a>
        )
      }

      return baseValue
    }

    const getValue = () => {
      const newValue = convertValueCallbackFunc
        ? convertValueCallbackFunc(baseValue, node)
        : baseValue ?? ''
      return appendPostfixCallbackFunc ? appendPostfixCallbackFunc(newValue, node) : newValue
    }

    return (
      <div className={labelClassNames}>
        <div
          className={isTextOverflowEllipsis ? 'cck-table-label-overflow-ellipsis' : ''}
          dangerouslySetInnerHTML={{ __html: getValue().replace(/\n/g, '<br>') }}
        />
      </div>
    )
  }

  return needToRenderPlaceholder ? renderPlaceholderCell() : renderValueCell()
}

export default LabelAgGridCellRendererComponent

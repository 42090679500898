import { CustomHeaderProps } from 'ag-grid-react'
import React, { useCallback, useEffect, useState } from 'react'

import CarbonCheckboxComponent from 'components/checkbox/CarbonCheckboxComponent'
import { isEmpty } from 'utils/common_utils'

interface CarbonCheckboxHeaderComponentProps extends CustomHeaderProps {
  isChecked?: boolean
}

const CarbonCheckboxHeaderComponent: React.FC<CarbonCheckboxHeaderComponentProps> = ({
  isChecked,
  api,
  column
}) => {
  const [checkState, setCheckState] = useState<boolean>(isChecked || false)

  const updateCheckState = useCallback(() => {
    const dataArray: string[] = []
    api.forEachNode((node) => {
      dataArray.push(node.data[column.getColId()])
    })

    if (isEmpty(dataArray)) {
      setCheckState(false)
      return
    }
    setCheckState(dataArray.every((item) => Boolean(item)))
  }, [api, column])

  useEffect(() => {
    updateCheckState()

    const onCellValueChanged = (params: any) => {
      if (params.column.getColId() === column.getColId()) {
        updateCheckState()
      }
    }
    const onRowDataUpdated = () => {
      updateCheckState()
    }
    api.addEventListener('cellValueChanged', onCellValueChanged)
    api.addEventListener('rowDataUpdated', onRowDataUpdated)
    return () => {
      api.removeEventListener('cellValueChanged', onCellValueChanged)
      api.removeEventListener('rowDataUpdated', onRowDataUpdated)
    }
  }, [api, column, updateCheckState])

  const handleCheckboxStateChange = (checked: boolean) => {
    setCheckState(checked)
    if (api) {
      api.forEachNode((node) => {
        node.setDataValue(column.getColId(), checked)
      })
    }
  }

  return (
    <div className="carbon-table-custom-header-frame">
      <CarbonCheckboxComponent
        checkboxProps={{ id: `carbon-check-header-${column.getColId()}`, labelText: '' }}
        checked={checkState}
        onChangeCallbackFun={(newChecked) => handleCheckboxStateChange(newChecked)}
      />
    </div>
  )
}

export default CarbonCheckboxHeaderComponent

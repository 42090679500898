import { ScrollBarComponent } from '@cck/react'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

import LoginWrapperComponent from 'component/login/LoginWrapperComponent'
import { useRedirect } from 'hook/redirect/useRedirect'

const MothershipMainViewRouteComponent: React.FC = () => {
  const { redirectHomepage } = useRedirect()

  const CCKSolutionRedirect = () => {
    React.useEffect(() => {
      redirectHomepage()
    }, [])

    return null
  }

  return (
    <ScrollBarComponent minHeight={920} minWidth={1440}>
      <Routes>
        <Route element={<LoginWrapperComponent />} path="/login" />
        <Route element={<CCKSolutionRedirect />} path="/*" />
        {/* <Route
          element={(
            <MothershipAuthProtectComponent>
              <MothershipMenuRouterComponent />
            </MothershipAuthProtectComponent>
          )}
          path="/menu/*"
        />
        <Route element={<Navigate replace to="/menu/dashboard" />} path="/*" /> */}
      </Routes>
    </ScrollBarComponent>
  )
}

export default MothershipMainViewRouteComponent

import { Loading } from '@carbon/react'
import { Backdrop } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'

import './loading.scss'

type LoadingOverlayComponentProps = {
  open: boolean
  // text가 없다면, loading indicator만 표시한다.
  text?: string
  // lineBreakChar가 있다면, text를 lineBreakChar로 split하여 각각의 줄로 표시한다.
  lineBreakChar?: string
  // isBlur가 true라면, 배경을 blur 처리한다. default true
  blur?: boolean
}

const LoadingOverlayComponent: React.FC<LoadingOverlayComponentProps> = ({
  open,
  text,
  lineBreakChar,
  blur = true
}) => {
  const [displayedText, setDisplayedText] = useState('')
  const [index, setIndex] = useState(0)
  const [textWidth, setTextWidth] = useState(0)

  useEffect(() => {
    setDisplayedText('')
    setIndex(0)

    if (!text) {
      return
    }

    // 텍스트 폭 계산
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (context) {
      context.font = '16px Pretendard'
      const metrics = context.measureText(text)
      setTextWidth(metrics.width + 4)
    }
  }, [text])

  useEffect(() => {
    if (!text) {
      return
    }

    let interval: NodeJS.Timeout
    if (index < text.length) {
      interval = setInterval(() => {
        setDisplayedText((prev) => prev + text[index])
        setIndex((prev) => prev + 1)
      }, 30)
    } else {
      interval = setTimeout(() => {
        setDisplayedText('')
        setIndex(0)
      }, 2000)
    }

    return () => clearInterval(interval)
  }, [index, text])

  const backdropStyle = useMemo(() => {
    return {
      zIndex: 10000,
      color: 'black',
      display: 'flex',
      flexDirection: 'column',
      backdropFilter: blur ? 'blur(1px)' : 'none',
      backgroundColor: 'rgba(255, 255, 255, 0.5)' // 배경색 설정
    }
  }, [blur])

  return (
    <Backdrop open={open} sx={backdropStyle}>
      <Loading description="Active loading indicator" withOverlay={false} />
      <div className="cck-loading-overlay-text-frame" style={{ width: `${textWidth}px` }}>
        {displayedText}
      </div>
    </Backdrop>
  )
}

export default LoadingOverlayComponent

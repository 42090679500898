import { CheckmarkFilled, Reset } from '@carbon/icons-react'
import { Button } from '@carbon/react'
import classNames from 'classnames'
import React from 'react'

import CarbonIconWrapperComponent from 'components/icon/CarbonIconWrapperComponent'

import './_progress.scss'

export enum ProgressStepState {
  NOT_YET,
  CURRENT,
  COMPLETED
}

export interface ProgressStepInfo {
  label: string
  stepState?: ProgressStepState
  isCurrent?: boolean
  clickCallbackFunc?: () => void
}

export interface ProgressNextStep {
  label: string
  disabled?: boolean
  clickCallbackFunc: () => void
}

interface ProgressStepWrapperComponentProps {
  steps: ProgressStepInfo[]
  next?: ProgressNextStep
  reset?: ProgressNextStep
}

const ProgressStepWrapperComponent: React.FC<ProgressStepWrapperComponentProps> = ({
  steps,
  next,
  reset
}) => {
  if (steps.length === 0) {
    return null
  }

  const getIcon = (isComplete?: ProgressStepState) => {
    if (isComplete === ProgressStepState.NOT_YET) {
      return (
        <CarbonIconWrapperComponent
          iconData={{
            imageString: 'CircleDash',
            width: 16,
            height: 16
          }}
        />
      )
    }

    if (isComplete === ProgressStepState.COMPLETED) {
      return (
        <CarbonIconWrapperComponent
          iconData={{
            imageString: 'CheckmarkOutline',
            width: 16,
            height: 16,
            fillColor: 'blue',
            fillColorProperty: 60
          }}
        />
      )
    }

    return (
      <CarbonIconWrapperComponent
        iconData={{
          imageString: 'Incomplete',
          width: 16,
          height: 16,
          fillColor: 'blue',
          fillColorProperty: 60
        }}
      />
    )
  }

  return (
    <div className="progress-step-frame-wrapper">
      <div className="progress-step-wrapper">
        {steps.map((step, index) => {
          const isComplete = step.stepState === ProgressStepState.COMPLETED
          const isCurrent = step.isCurrent ?? false
          const isDisabled = step.stepState === ProgressStepState.NOT_YET

          return (
            <div
              className={classNames({
                'progress-step-frame': true,
                'progress-step-current': isCurrent,
                'progress-step-completed': isComplete,
                'progress-step-disabled': isDisabled
              })}
              key={`${step.label}-${index}`}
              onClick={() => {
                if (isDisabled) {
                  return
                }
                step.clickCallbackFunc?.()
              }}
            >
              {getIcon(step.stepState ?? ProgressStepState.NOT_YET)}
              <div className="progress-step-label">{step.label}</div>
            </div>
          )
        })}
      </div>
      <div className="progress-stop-button-wrapper">
        {reset && (
          <div className="progress-step-button">
            <Button
              disabled={reset.disabled ?? false}
              kind="tertiary"
              renderIcon={Reset}
              size="sm"
              onClick={() => {
                reset.clickCallbackFunc()
              }}
            >
              {reset.label}
            </Button>
          </div>
        )}
        {next && (
          <div className="progress-step-button">
            <Button
              disabled={next.disabled ?? false}
              kind="primary"
              renderIcon={CheckmarkFilled}
              size="sm"
              onClick={() => {
                next.clickCallbackFunc()
              }}
            >
              {next.label}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProgressStepWrapperComponent

import { useState, useEffect } from 'react'

import { mothershipTokenManager } from 'class/token-manager/MothershipTokenManager'

export const useTokenChange = () => {
  const [accessToken, setAccessToken] = useState<string | null>(
    mothershipTokenManager.getAccessToken()
  )

  useEffect(() => {
    // 같은 탭에서의 토큰 변경 감지
    const removeTokenListener = mothershipTokenManager.addTokenChangeListener((token) => {
      setAccessToken(token)
    })

    // 다른 탭에서의 변경 감지
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'access_token') {
        setAccessToken(mothershipTokenManager.getAccessToken())
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      removeTokenListener()
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [accessToken])

  return {
    accessToken,
    isAuthenticated: !!accessToken && !mothershipTokenManager.isTokenExpired()
  }
}

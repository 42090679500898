import React, { useMemo } from 'react'
import { Scrollbars, positionValues } from 'react-custom-scrollbars-2'

export type ScrollBarRefType = Scrollbars

// *** ScrollBarComponentProps ***
// [ children ]
// React.ReactNode

// [ minWidth / minHeight ]
// 기본적으로 scroll bar component는 부모 element의 100% width/height 사용
// scroll bar가 생성되는 최소 크기를 설성
// 단위 : px

// [ autoHideEnable ]
// 일정 시간 이후 자동으로 스크롤이 보이지 않게 하고 싶을 때 사용
// default false

// [ theme ]
// 스크롤바의 테마를 설정
// default dark

// [ scrollBarRef ]
// Scrollbars 컴포넌트의 ref를 받아오기 위한 ref
// 보통 사용 컴포넌트에서 특정 이벤트 시, scroll bar의 위치를 조정하기 위해 사용 (위치 초기화 등)

export enum ScrollBarTheme {
  // eslint-disable-next-line no-unused-vars
  INVISIBLE = 'invisible',
  // eslint-disable-next-line no-unused-vars
  LIGHT = 'light',
  // eslint-disable-next-line no-unused-vars
  DEEP_DARK = 'deep-dark',
  // eslint-disable-next-line no-unused-vars
  DARK = 'dark'
}

type ScrollBarComponentProps = {
  children: React.ReactNode
  minWidth?: number
  minHeight?: number
  autoHideEnable?: boolean
  // default dark
  theme?: ScrollBarTheme
  scrollBarRef?: React.RefObject<ScrollBarRefType>
  onScrollFrame?: (values: positionValues) => void
}

const ScrollBarComponent: React.FC<ScrollBarComponentProps> = ({
  children,
  minWidth = 0,
  minHeight = 0,
  autoHideEnable = false,
  theme = ScrollBarTheme.DARK,
  scrollBarRef,
  onScrollFrame
}) => {
  const backgroundColor = useMemo(() => {
    const lightColor = 'white'
    const darkColor = '#C6C6C6' // carbon default gray 30
    const deepDarkColor = '#252a23'
    switch (theme) {
      case ScrollBarTheme.LIGHT:
        return lightColor
        break
      case ScrollBarTheme.DEEP_DARK:
        return deepDarkColor
        break
      case ScrollBarTheme.DARK:
      default:
        return darkColor
    }
  }, [theme])

  const renderTrackVertical = ({ style, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
    const trackStyle = {
      position: 'absolute' as const,
      top: '2px',
      right: '8px',
      bottom: '2px',
      width: '5px',
      borderRadius: '10px'
    }
    return (
      <div className="scroll-bar-track-vertical" style={{ ...style, ...trackStyle }} {...props} />
    )
  }

  const renderTrackHorizontal = ({ style, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
    const trackStyle = {
      position: 'absolute' as const,
      left: '2px',
      bottom: '8px',
      right: '2px',
      height: '5px',
      borderRadius: '10px'
    }
    return (
      <div className="scroll-bar-track-horizontal" style={{ ...style, ...trackStyle }} {...props} />
    )
  }

  const renderThumbStyle = () => {
    if (theme === ScrollBarTheme.INVISIBLE) {
      return <div></div>
    }

    const commonStyle = {
      borderRadius: '10px',
      opacity: 0.3,
      width: '8px',
      height: '8px',
      cursor: 'pointer'
    }

    // theme === 'light' | 'dark' | 'deep-dark'
    return <div style={{ ...commonStyle, backgroundColor }} />
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%'
      }}
    >
      <Scrollbars
        autoHide={autoHideEnable}
        ref={scrollBarRef}
        renderThumbHorizontal={renderThumbStyle}
        renderThumbVertical={renderThumbStyle}
        renderTrackHorizontal={renderTrackHorizontal}
        renderTrackVertical={renderTrackVertical}
        onScrollFrame={onScrollFrame}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            minWidth: `${minWidth}px`,
            minHeight: `${minHeight}px`
          }}
        >
          {children}
        </div>
      </Scrollbars>
    </div>
  )
}

export default ScrollBarComponent

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Column, GridApi, IRowNode, ProcessDataFromClipboardParams } from 'ag-grid-community'
import _ from 'lodash'

import { removeEmptyStringArrays } from 'utils/format_utils'

export function checkValueIsUnique<T extends object>(
  targetObjectArray: T[],
  targetKey: string,
  targetValue: string
): boolean {
  return !targetObjectArray.some(
    (targetObject) => targetObject[targetKey as keyof T] === targetValue
  )
}

export function calculateTableMaxHeight(tableWrapper: HTMLDivElement): number {
  const childCount = tableWrapper.childElementCount
  if (childCount < 1) {
    // eslint-disable-next-line no-console
    console.error('CarbonTable Child Count Zero Error:', tableWrapper)
    return 0
  }

  const currentHeight = tableWrapper.clientHeight
  if (childCount === 1) {
    // table만 있는 경우
    return currentHeight
  }

  // table 외에 다른 element가 있는 경우(ex. 검색창 등)
  let otherChildHeight = 0

  for (let i = 0; i < childCount - 1; i++) {
    otherChildHeight += tableWrapper.children[i].clientHeight
  }
  return currentHeight - otherChildHeight
}

export function getAllRowData(gridApi: GridApi): { rowIndex: number; data: any }[] {
  const rowData: { rowIndex: number; data: any }[] = []
  gridApi.forEachNode((node, index) =>
    rowData.push({
      rowIndex: index,
      data: node.data
    })
  )

  return rowData
}

type CellFlashType = 'success' | 'error' | 'warning' | 'info'

type CellFlashParams = {
  api: GridApi
  rowNodes: IRowNode[]
  columns?: Column[]
  flashType?: CellFlashType
  flashDelay?: number
  fadeDelay?: number
  targetElement?: HTMLElement
}

export function showFlashCellsSimple(flashParams: CellFlashParams): void {
  flashParams.api.refreshCells({
    rowNodes: flashParams.rowNodes,
    columns: flashParams.columns
  })
}

/**
 * cck table의 flash 효과는 table wrapper element에 class를 추가하면
 * agGrid에서 자체적으로 하이라이트 style을 적용하는 형태임
 * 그러나 32.0.2 버전에서는 filed를 지정하면 flash가 적용되지 않는 문제가 있어
 * 자체적으로 row와 cell에 class를 추가하여 flash 효과를 구현
 */
export function showFlashRow(flashParams: CellFlashParams): void {
  const rowElement = flashParams.targetElement?.parentElement
  const cellElement = flashParams.targetElement
  if (!rowElement || !cellElement) {
    return
  }
  const cellFlashClassName = `carbon-cell-flash-${flashParams.flashType ?? 'success'}`

  rowElement?.classList.add(cellFlashClassName)
  cellElement?.classList.add(cellFlashClassName)

  // flash가 끝난 후에 후처리를 수행
  setTimeout(() => {
    rowElement?.classList.remove(cellFlashClassName)
    cellElement?.classList.remove(cellFlashClassName)
  }, flashParams.flashDelay || 1000)
}

export const pasteDataWithNewRow = (
  params: ProcessDataFromClipboardParams,
  gridApi?: GridApi,
  maxRow = 100
) => {
  const adjustedData = removeEmptyStringArrays(params.data)
  const api = gridApi || params.api
  if (!api || api === null) {
    return adjustedData
  }

  const pasteRows = adjustedData.length
  const currentRowIndex = api.getFocusedCell()?.rowIndex || 0
  const rowCount = api.getDisplayedRowCount() || 0
  const missingRowCount = currentRowIndex + pasteRows - rowCount
  if (missingRowCount) {
    const totalRowCount = rowCount + missingRowCount
    const rowsToAdd = Math.min(missingRowCount, maxRow - rowCount)
    for (let i = 0; i < rowsToAdd; i += 1) {
      const row = _.cloneDeep({ Index: 0 })
      row.Index = api.getDisplayedRowCount() + 1
      api.applyTransaction({ add: [row] })
    }
    // 최대 행 수를 초과하는 경우 경고 메시지 출력
    if (totalRowCount > maxRow) {
      // eslint-disable-next-line no-console
      console.warn(`Cannot add all pasted rows. Maximum row limit of ${maxRow} exceeded.`)
    }
  }

  return adjustedData
}

export const manualRowNodeSort = (
  inputData: any[],
  currentSortModel: {
    colId: string | undefined
    sort: string | null | undefined
  }[]
): any[] => {
  const result = [...inputData]

  // 정렬 로직 (예시: 단일 열 정렬)
  if (currentSortModel.length && currentSortModel[0].colId && currentSortModel[0].sort) {
    const sortColId = currentSortModel[0].colId
    const sortDirection = currentSortModel[0].sort

    result.sort((a, b) => {
      const valA = a[sortColId]
      const valB = b[sortColId]

      if (valA < valB) {
        return sortDirection === 'asc' ? -1 : 1
      }
      if (valA > valB) {
        return sortDirection === 'asc' ? 1 : -1
      }
      return 0
    })
  }

  return result
}

import { magenta, orange, purple, yellow, teal } from '@carbon/colors'
import { ColDef } from 'ag-grid-enterprise'
import { Dropdown, type MenuProps } from 'antd'
import { nanoid } from 'nanoid'
import React from 'react'
import { Column, keyColumn, textColumn } from 'react-datasheet-grid'

import CarbonIconWrapperComponent from 'components/icon/CarbonIconWrapperComponent'
import { SheetDataCategoryType, SheetDataRange } from 'components/sheet/SheetDataInsertComponent'
import { createCarbonLabelColumnDef } from 'components/table/column/CarbonColumnDefGenerator'
import { CarbonAgGridHeader } from 'components/table/column/CarbonColumnDefTypes'

const DataSheetNumberWidth = 50

const DataSheetColumnWidth = 120

export const BASIC_COLUMN_TYPE = {
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
  G: 'G',
  H: 'H',
  I: 'I',
  J: 'J',
  K: 'K',
  L: 'L',
  M: 'M',
  N: 'N',
  O: 'O',
  P: 'P',
  Q: 'Q',
  R: 'R',
  S: 'S',
  T: 'T',
  U: 'U',
  V: 'V',
  W: 'W',
  X: 'X',
  Y: 'Y',
  Z: 'Z'
} as const

export type BASIC_COLUMN_TYPE = typeof BASIC_COLUMN_TYPE[keyof typeof BASIC_COLUMN_TYPE]

type InsertDataRowBasicType = Record<keyof typeof BASIC_COLUMN_TYPE, string | null>

export type InsertDataRowType = InsertDataRowBasicType & { id: string }

type MenuItem = Required<MenuProps>['items'][number]

const createEmptyColumn = (): Column<InsertDataRowType>[] => {
  return Object.keys(BASIC_COLUMN_TYPE).map((key) => {
    const strKey = key as keyof typeof BASIC_COLUMN_TYPE
    return {
      ...keyColumn<InsertDataRowType, typeof strKey>(BASIC_COLUMN_TYPE[strKey], textColumn),
      title: BASIC_COLUMN_TYPE[strKey],
      prePasteValues: (prePasteValues) => {
        return prePasteValues.map((value) => {
          return value ?? ''
        })
      },
      minWidth: 200
    }
  })
}

const createEmptyRow = (): InsertDataRowType => {
  const row: Partial<InsertDataRowType> = {}

  Object.keys(BASIC_COLUMN_TYPE).forEach((key) => {
    row[BASIC_COLUMN_TYPE[key as keyof typeof BASIC_COLUMN_TYPE]] = null
  })

  row.id = nanoid()

  return row as InsertDataRowType
}

export const createEmptyRowList = (count = 50): InsertDataRowType[] => {
  return Array.from({ length: count }, () => createEmptyRow())
}

export const createInsertSheetColumns = (
  categoryTypes: SheetDataCategoryType[],
  openChangeCallbackFun: (targetColumn: BASIC_COLUMN_TYPE) => void,
  clearHeaderSelectionCallbackFunc: () => void,
  addCategoryRangeCallbackFun: (categoryType: SheetDataCategoryType) => void,
  clearCategoryRangeCallbackFun: (targetColumn: BASIC_COLUMN_TYPE) => void,
  readonly: boolean,
  selectedHeaders: string[],
  categoryRanges?: SheetDataRange[]
): ColDef[] => {
  const numberColumn: ColDef = {
    field: '#',
    headerName: '-',
    width: DataSheetNumberWidth,
    valueGetter: (params) => {
      const index = params.node?.rowIndex
      if (index === null || index === undefined) {
        return
      }
      return index + 1
    },
    flex: 0,
    suppressHeaderMenuButton: true,
    sortable: false,
    suppressMovable: false,
    resizable: false
  }

  const columns = Object.keys(BASIC_COLUMN_TYPE).map((key) => {
    const strKey = key as keyof typeof BASIC_COLUMN_TYPE
    const value = BASIC_COLUMN_TYPE[strKey]

    const menuItems: MenuItem[] = categoryTypes.map((categoryType) => {
      const targetCategoryRange = categoryRanges?.find((range) => range.key === categoryType.key)
      const isSelected = selectedHeaders.includes(value)
      let isIncluded = false
      if (isSelected && targetCategoryRange) {
        const normalizedCategoryRange = normalizeSheetDataRange({
          start: selectedHeaders[0],
          end: selectedHeaders[selectedHeaders.length - 1],
          key: 'selection'
        })
        if (
          targetCategoryRange.start <= normalizedCategoryRange.start &&
          targetCategoryRange.end >= normalizedCategoryRange.end
        ) {
          isIncluded = true
        }
      }
      const getLabel = () => {
        return (
          <div className="data-sheet-dropdown-item-category">
            {isIncluded && (
              <CarbonIconWrapperComponent
                iconData={{
                  imageString: 'Checkmark',
                  isSvg: false,
                  width: 16,
                  height: 16
                }}
              />
            )}
            {categoryType.label}
          </div>
        )
      }
      return {
        key: categoryType.key,
        label: getLabel(),
        onClick: () => {
          if (isIncluded) {
            clearCategoryRangeCallbackFun(value)
            return
          }
          addCategoryRangeCallbackFun(categoryType)
        }
      }
    })

    // menuItems.push({
    //   key: 'divider',
    //   type: 'divider'
    // })

    // menuItems.push({
    //   key: 'clear',
    //   label: (
    //     <div className="data-sheet-dropdown-item-reset">
    //       지정 초기화
    //       <CarbonIconWrapperComponent
    //         iconData={{
    //           imageString: 'Reset',
    //           isSvg: false,
    //           width: 18,
    //           height: 18
    //         }}
    //       />
    //     </div>
    //   ),
    //   onClick: () => {
    //     clearCategoryRangeCallbackFun(value)
    //   }
    // })

    return createCarbonLabelColumnDef({
      field: value,
      headerName: value,
      headerClass: `data-sheet-header sheet-header-${value}`,
      onCellClicked: () => clearHeaderSelectionCallbackFunc(),
      editable: !readonly,
      suppressHeaderMenuButton: true,
      sortable: false,
      suppressMovable: true,
      width: DataSheetColumnWidth,
      flex: 0,
      customHeaderType: CarbonAgGridHeader.CustomHeader,
      customColumnValue: (
        <div className="data-sheet-custom-header">
          <Dropdown
            disabled={readonly}
            menu={{
              items: menuItems
            }}
            overlayClassName="data-sheet-dropdown-overlay"
            trigger={['contextMenu']}
            onOpenChange={(open) => {
              if (open) {
                openChangeCallbackFun(value)
              }
            }}
          >
            <div className="data-sheet-dropdown-item">
              <div className="data-sheet-dropdown-item-text">{value}</div>
              {!readonly && (
                <Dropdown
                  menu={{
                    items: menuItems
                  }}
                  overlayClassName="data-sheet-dropdown-overlay"
                  trigger={['click']}
                  onOpenChange={(open) => {
                    if (open) {
                      openChangeCallbackFun(value)
                    }
                  }}
                >
                  <div
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                    }}
                  >
                    <CarbonIconWrapperComponent
                      iconData={{
                        imageString: 'CaretDown',
                        isSvg: false,
                        width: 18,
                        height: 18
                      }}
                    />
                  </div>
                </Dropdown>
              )}
            </div>
          </Dropdown>
        </div>
      ),
      cellClass: `sheet-cell-${value}`,
      resizable: false
    })
  })

  return [numberColumn, ...columns]
}

const createEmptySheetRowData = () => {
  const row: Partial<InsertDataRowType> = {}

  Object.keys(BASIC_COLUMN_TYPE).forEach((key) => {
    row[BASIC_COLUMN_TYPE[key as keyof typeof BASIC_COLUMN_TYPE]] = null
  })

  return row as InsertDataRowType
}

// export const createEmptySheetRowData = (): InsertDataRowType[] => {

export function isOverlapping(range1: SheetDataRange, range2: SheetDataRange): boolean {
  // 문자를 대문자로 변환하고 ASCII 코드로 변환
  const start1 = range1.start.toUpperCase().charCodeAt(0)
  const end1 = range1.end.toUpperCase().charCodeAt(0)
  const start2 = range2.start.toUpperCase().charCodeAt(0)
  const end2 = range2.end.toUpperCase().charCodeAt(0)

  // 각 범위의 시작과 끝을 정렬
  const [min1, max1] = start1 <= end1 ? [start1, end1] : [end1, start1]
  const [min2, max2] = start2 <= end2 ? [start2, end2] : [end2, start2]

  // 겹치는 경우를 확인
  return min1 <= max2 && max1 >= min2
}

export function normalizeSheetDataRange(range: SheetDataRange): SheetDataRange {
  const start = range.start.toUpperCase()
  const end = range.end.toUpperCase()

  // start가 end보다 알파벳 순으로 뒤에 있는 경우 교환
  if (start.charCodeAt(0) > end.charCodeAt(0)) {
    return {
      ...range,
      start: end,
      end: start
    }
  }

  // 이미 정상적인 순서인 경우 원본 객체 반환
  return range
}

export const getAlphabetRange = (start: string, end: string) => {
  // 시작과 끝 문자의 ASCII 코드 얻기
  const startCode = start.charCodeAt(0)
  const endCode = end.charCodeAt(0)

  // 결과 배열 초기화
  const result: string[] = []

  // 시작 코드가 끝 코드보다 작거나 같으면 오름차순으로 처리
  if (startCode <= endCode) {
    for (let i = startCode; i <= endCode; i++) {
      result.push(String.fromCharCode(i))
    }
  } else {
    // 시작 코드가 끝 코드보다 크면 내림차순으로 처리
    for (let i = startCode; i >= endCode; i--) {
      result.push(String.fromCharCode(i))
    }
  }

  return result
}

const letterToNumber = (letter: string) => {
  const charCode = letter.charCodeAt(0)

  return charCode - 65
}

export const sheetColorList = [magenta[10], yellow[10], teal[10], purple[10], orange[10]]

const isValueIncludedCategoryRange = (value: string, categoryRange?: SheetDataRange) => {
  if (categoryRange === undefined) {
    return false
  }

  if (categoryRange.start <= value && value <= categoryRange.end) {
    return true
  }
  return false
}

import { ColDef, ColGroupDef, Column, GridApi, RowNode } from 'ag-grid-community'
import React from 'react'

import {
  LabelTextAlign,
  LabelTextVerticalAlign
} from 'components/table/control/CarbonLabelAgGridCellRendererComponent'
// Ag-grid에서 사용하는 cellRenderer를 custom component로 wrapping하기 위한 type 정의
export const CarbonAgGridCellRenderer = {
  Label: 'label',
  MoreLabel: 'moreLabel',
  Custom: 'custom',
  Checkbox: 'checkbox'
} as const

export type CarbonAgGridCellRenderer =
  typeof CarbonAgGridCellRenderer[keyof typeof CarbonAgGridCellRenderer]

export const CarbonAgGridCellEditor = {
  Basic: 'Basic',
  Modal: 'modal',
  AutoComplete: 'autoComplete',
  OnlyPaste: 'onlyPaste'
} as const

export type CarbonAgGridCellEditor =
  typeof CarbonAgGridCellEditor[keyof typeof CarbonAgGridCellEditor]

export const CarbonAgGridHeader = {
  CustomHeader: 'customHeader',
  CustomGroupHeader: 'customGroupHeader',
  EssentialHeader: 'essentialHeader',
  CheckBoxHeader: 'checkBoxHeader'
} as const

export type CarbonAgGridHeader = typeof CarbonAgGridHeader[keyof typeof CarbonAgGridHeader]

export const CarbonAgGridDateEditor = {
  Date: 'date',
  DateRange: 'dateRange'
} as const

export const CarbonAgGridLabelType = {
  Link: 'link',
  Basic: 'Basic',
  Amount: 'Amount',
  AmountWithoutHandleMinus: 'AmountWithoutHandleMinus'
} as const

export type CarbonAgGridLabelType = typeof CarbonAgGridLabelType[keyof typeof CarbonAgGridLabelType]

export type CarbonAgGridDateEditor =
  typeof CarbonAgGridDateEditor[keyof typeof CarbonAgGridDateEditor]

export interface CarbonAgGridCustomHeaderProps {
  isSortable?: boolean
}

// ag-grid에서 Column Definition을 생성할 때 공통적으로 사용하는 Common Props
export interface CarbonCommonColumnDefProps extends ColDef {
  // TODO (jaegon 23.07.27)
  // CommonAgGridCellRendererProps에서 정의하는 것과 동일한 형태를 유지해야함. 추후 개선 필요
  // check specific cell is visiable or not
  callbackIsContentVisible?: (node: RowNode, column: Column) => boolean
  // check specific cell is disable or not
  callbackIsContentDisable?: (node: RowNode, column: Column) => boolean

  // view cell left border
  cellLeftBorder?: boolean

  // view column left border
  columnLeftBorder?: boolean

  // view cell bottom border highlight
  cellBottomBorderHighlight?: boolean

  customHeaderType?: CarbonAgGridHeader

  customColumnValue?: React.ReactNode

  customHeaderProps?: CarbonAgGridCustomHeaderProps

  isHeaderCenterAlign?: boolean

  isHeaderRightAlign?: boolean

  isCellCenterAlign?: boolean
}

// Checkbox column definition 생성 시 사용하는 Props
export interface CarbonCheckboxColumnDefProps extends CarbonCommonColumnDefProps {
  checked?: boolean
  // Checkbox가 변경되었을 때 호출되는 callback
  // TODO (jaegon 23.07.27)
  // CheckBoxAgGridRendererComponent의 Prop에서 정의하는 것과 동일한 형태를 유지해야함. 추후 개선 필요
  callbackCheckboxChange?: (
    checked: boolean,
    gridApi: GridApi,
    targetColumnNode: RowNode,
    column: Column
  ) => void
  checkboxClickDisabled?: boolean
}

// Label column definition 생성 시 사용하는 Props
export interface CarbonLabelColumnDefProps extends CarbonCommonColumnDefProps {
  placeholder?: string | ((node: RowNode, column: Column) => string | undefined)
  postfixPlaceholder?: string
  // Render Params : Row Data상의 값이랑 다른 값으로 Label을 표시하기 위한 callback
  convertValueCallbackFunc?: (value: string, node?: RowNode) => string
  // Editor Params : unique value check를 수행할지 여부
  checkUniqueValueInColumn?: boolean
  // Editor Params : input 값 validation check를 위한 callback
  isValidateCheckCallbackFunc?: (
    input: string,
    node: RowNode,
    column: Column,
    api: GridApi
  ) => boolean
  // Editor Params : input 값이 최종적으로 Cell에 입력되기 전애 보정하기 위한 callback
  refinedInputValueCallbackFunc?: (input: string, node: RowNode, column: Column) => string
  // Editor Params : input 값이 변경될 때마다 호출되는 callback
  changeInputValueFilterCallbackFunc?: (input: string, node: RowNode, column: Column) => string
  isTextOverflowEllipsis?: boolean
  appendPostfixCallbackFunc?: (value: string, node?: RowNode) => string
  moveNextAfterEdit?: boolean
  labelType?: CarbonAgGridLabelType
  labelTextAlign?: LabelTextAlign
  labelTextVerticalAlign?: LabelTextVerticalAlign
}

export interface CarbonCustomColumnDefProps extends CarbonCommonColumnDefProps {
  childNode?: React.ReactNode
  isSearchBlocked?: boolean
}

export interface DateAgGridCellRendererProps extends CarbonCommonColumnDefProps {
  showTime?: boolean
  minDate?: string
  maxDate?: string
}

export interface CarbonMoreLabelColumnDefProps extends CarbonCommonColumnDefProps {
  lineCount: number
}

export interface CarbonCommonGroupColumnDefProps extends ColGroupDef {
  isHeaderCenterAlign?: boolean

  isHeaderRightAlign?: boolean
}

export interface CarbonCustomGroupColumnDefProps extends CarbonCommonGroupColumnDefProps {
  customColumnValue: React.ReactNode
}

interface RedirectProps {
  redirectHomepage: () => void
}

const DEFAULT_HOMEPAGE_URL = 'https://www.ccksolution.co.kr/'

export const useRedirect = (): RedirectProps => {
  const homepageUrl = process.env.REACT_APP_HOMEPAGE_URL || DEFAULT_HOMEPAGE_URL

  const redirectHomepage = () => {
    window.location.href = homepageUrl
  }

  return {
    redirectHomepage
  }
}

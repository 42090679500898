import {
  AbstractColDef,
  AgColumn,
  AgColumnGroup,
  ColDef,
  ColGroupDef,
  GridApi,
  GridOptions,
  GridReadyEvent
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import classNames from 'classnames'
import { debounce } from 'lodash'
import React, { useCallback, useRef, useState, useMemo, useLayoutEffect } from 'react'

import './_table.scss'
import { CarbonAgGridFrameworkComponents } from 'components/table/column/CarbonColumnDefGenerator'
import { AG_GRID_LOCALE_KR } from 'components/table/locale/AggridLocaleText'
import { pasteDataWithNewRow } from 'components/table/util/CarbonTableUtil'

interface PasteProps<T> {
  setRowDataAfterPaste: (rowData: T) => void
  isRemoveNewLine?: boolean
  maxRow?: number
}

interface CarbonAgGridTableProps {
  isVerticalTable?: boolean
}

type CarbonAgGridTableComponentProps<T> = {
  tableRowData?: T[]
  colDefs: AbstractColDef[]
  gridOptions?: GridOptions
  pasteProps?: PasteProps<T>
  onGridReadyCallbackFunc?: (ready: GridReadyEvent) => void
  tableProps?: CarbonAgGridTableProps
  isAutoWidth?: boolean
  isAutoHeight?: boolean
  calculateState?: boolean
}

const TABLE_ROW_HEIGHT = 26
const FULL_WIDTH_PIXEL = '100%'

const CarbonAgGridTableComponent: React.FC<CarbonAgGridTableComponentProps<any>> = ({
  tableRowData,
  colDefs,
  gridOptions,
  pasteProps,
  onGridReadyCallbackFunc,
  tableProps,
  isAutoWidth = false,
  isAutoHeight = false,
  calculateState = true
}) => {
  const gridApiRef = useRef<GridApi | null>(null)
  const [gridReady, setGridReady] = useState<GridReadyEvent>()
  const [keyIndex, setKeyIndex] = useState(0)
  const frameTarget = useRef<HTMLDivElement | null>(null)
  const [tableWidth, setTableWidth] = useState<string>(FULL_WIDTH_PIXEL)
  const [isCalculating, setIsCalculating] = useState(false)
  const [recalculateScroll, setRecalculateScroll] = useState(false)

  const calculateTableWidth = useCallback(
    (api: GridApi) => {
      if (!isAutoWidth || !frameTarget.current) {
        return FULL_WIDTH_PIXEL
      }

      const targetColumns = api.getAllDisplayedColumns()
      if (targetColumns === undefined || targetColumns.length === 0) {
        return FULL_WIDTH_PIXEL
      }

      const parentWidth = frameTarget.current.parentElement?.clientWidth
      if (parentWidth === undefined) {
        return FULL_WIDTH_PIXEL
      }

      const getHeaderGroupDepth = (column: AgColumn | AgColumnGroup, depth = 1): number => {
        const parentColumn = column.getParent()
        if (!parentColumn) {
          return depth
        }

        return getHeaderGroupDepth(parentColumn, depth + 1)
      }

      let totalColumnWidth = 0
      let totalHeaderHeight = 0
      targetColumns.forEach((column, index) => {
        const colDef = column.getColDef()
        if (colDef.width !== undefined) {
          totalColumnWidth += colDef.width
        }

        if (index > 0) {
          return
        }

        // 한번만 계산해도 됨
        totalHeaderHeight =
          getHeaderGroupDepth(column as AgColumn) * (gridOptions?.rowHeight || TABLE_ROW_HEIGHT)
      })

      let totalRowHeight = 0

      api.forEachNodeAfterFilterAndSort((node) => {
        if (node.displayed) {
          totalRowHeight += node.rowHeight || 0
        }
      })

      const parentHeight = frameTarget.current.parentElement?.clientHeight || 0
      const isOverContainerHeight = parentHeight <= totalRowHeight + totalHeaderHeight
      const offsetWidth = isOverContainerHeight ? 27 : 2

      const domLayout = api.getGridOption('domLayout')

      if (isOverContainerHeight) {
        // 넘어섰는데 autoheight이면 autoheight 해제
        if (domLayout === 'autoHeight') {
          api.updateGridOptions({
            domLayout: undefined
          })
        }
      } else {
        // 안넘어섰는데 autoheight이면 autoheight 설정
        if (domLayout !== 'autoHeight') {
          api.updateGridOptions({
            domLayout: 'autoHeight'
          })
        }
      }

      return `${totalColumnWidth + offsetWidth}px`
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAutoWidth, isAutoHeight]
  )

  const debouncedCalculateWidth = useMemo(
    () =>
      debounce((api: GridApi) => {
        const calculatedWidth = calculateTableWidth(api)
        setTableWidth(calculatedWidth)
        setIsCalculating(false)
      }, 300),
    [calculateTableWidth]
  )

  const onGridReady = useCallback(
    (ready: GridReadyEvent) => {
      setGridReady(ready)
      gridApiRef.current = ready.api

      if (onGridReadyCallbackFunc) onGridReadyCallbackFunc(ready)
    },
    [onGridReadyCallbackFunc]
  )

  useLayoutEffect(() => {
    if (gridReady && isAutoWidth) {
      if (calculateState) {
        setIsCalculating(true)
      }
      debouncedCalculateWidth(gridReady.api)
      setRecalculateScroll(false)
    }

    return () => {
      debouncedCalculateWidth.cancel()
    }
  }, [gridReady, debouncedCalculateWidth, isAutoWidth, colDefs, recalculateScroll, calculateState])

  function isColDef<TData = any>(col: ColDef<TData> | ColGroupDef<TData>): col is ColDef<TData> {
    return (col as ColDef<TData>).sort !== undefined
  }

  const defaultGridOptions: GridOptions = useMemo(
    () => ({
      domLayout: isAutoHeight ? 'autoHeight' : undefined,
      rowHeight: TABLE_ROW_HEIGHT,
      headerHeight: TABLE_ROW_HEIGHT,
      undoRedoCellEditing: true, // 셀 편집 취소 및 복구 가능하도록
      undoRedoCellEditingLimit: 5, // 셀 편집 취소 및 복구 최대 횟수
      enableRangeSelection: true, // 셀 범위 선택 가능하도록
      // 붙여넣기 시 현재 row의 포커스 셀의 row Index 및 rowCount 등을 비교하여, 새로운 row를 추가하게 만든 붙여넣기 동작
      processDataFromClipboard: (params) => {
        return pasteDataWithNewRow(params, gridApiRef.current!, pasteProps?.maxRow)
      },
      localeText: AG_GRID_LOCALE_KR,
      onPasteEnd: (params) => {
        if (pasteProps) {
          let sortedRowNode: any[] = []
          params.api.forEachNode((rowData) => {
            sortedRowNode.push(rowData.data)
          })

          if (pasteProps.isRemoveNewLine) {
            sortedRowNode = sortedRowNode.map((rowData) => {
              Object.keys(rowData).forEach((key) => {
                if (typeof rowData[key] === 'string') {
                  rowData[key] = rowData[key].replace(/\n/g, '')
                }
              })

              return rowData
            })
          }

          // 붙여넣기 시 정렬 취소
          params.api.getColumnDefs()?.forEach((colDef) => {
            if (isColDef(colDef)) {
              colDef.sortable = false
            }
          })

          pasteProps.setRowDataAfterPaste(sortedRowNode)
        }

        setKeyIndex((prev) => prev + 1)
      },
      tooltipShowDelay: 500,
      components: CarbonAgGridFrameworkComponents,
      // 다른 cell로 이동 시 자동으로 편집 종료
      stopEditingWhenCellsLoseFocus: true,
      // scroll 생성 시 blur 처리되지 않도록 수정
      rowBuffer: 0,
      // 일반적으로 editable이 있는 경우만 paste 가능하도록 설정
      suppressClipboardPaste: !colDefs.some(
        (colDef): colDef is ColDef & { editable: true } => (colDef as ColDef).editable === true
      ),
      cellFlashDuration: 2000,
      cellFadeDuration: 500
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [colDefs, pasteProps, isAutoHeight, recalculateScroll]
  )

  const defaultColDef: ColDef = useMemo(
    () => ({
      flex: 1,
      suppressSizeToFit: true
    }),
    []
  )

  const memoizedTableRowData = useMemo(() => tableRowData, [tableRowData])
  const memoizedColDefs = useMemo(() => colDefs, [colDefs])

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {isCalculating && <div className="carbon-ag-grid-overlay" />}
      <div
        className={classNames('carbon-ag-grid-table-frame', 'ag-theme-alpine', {
          'carbon-ag-grid-vertical-table': tableProps?.isVerticalTable,
          'carbon-ag-grid-overlay-children': isCalculating
        })}
        ref={frameTarget}
        style={{
          position: 'relative',
          width: tableWidth,
          height: '100%',
          maxWidth: '100%'
        }}
      >
        <AgGridReact
          columnDefs={memoizedColDefs}
          defaultColDef={defaultColDef}
          gridOptions={{
            ...defaultGridOptions,
            ...gridOptions
          }}
          key={keyIndex}
          rowData={memoizedTableRowData}
          onGridReady={onGridReady}
          onRowGroupOpened={(event) => {
            const rowGroupOpenedCallback = gridOptions?.onRowGroupOpened
            if (!rowGroupOpenedCallback) {
              return
            }

            rowGroupOpenedCallback(event)
            setRecalculateScroll(true)
          }}
        />
      </div>
    </div>
  )
}

export default React.memo(CarbonAgGridTableComponent)

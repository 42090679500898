import { CarbonInputComponent, isEmpty, NOTIFICATION_KIND, useMainNotification } from '@cck/react'
import { Button } from 'antd'
import React, { useState } from 'react'
import { useSetRecoilState } from 'recoil'

import { login } from 'api/user/UserAPI'
import { accountInfoAtom } from 'atoms/Account'
import { mothershipTokenManager } from 'class/token-manager/MothershipTokenManager'
import { useRedirect } from 'hook/redirect/useRedirect'

const LoginViewComponent: React.FC = () => {
  const { redirectHomepage } = useRedirect()
  const setAccountInfo = useSetRecoilState(accountInfoAtom)
  const { showBackdrop, closeBackdrop, addNotification } = useMainNotification()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const handleLoginCallbackFun = () => {
    if (isEmpty(email) || isEmpty(password)) {
      return
    }

    showBackdrop('로그인 중입니다...')
    login(email, password)
      .then((loginResult) => {
        mothershipTokenManager.setAccessToken(loginResult.accessToken)

        setAccountInfo({
          email: 'temp@email.com',
          name: 'temp user',
          lastLoginAt: new Date().toISOString()
        })
      })
      .catch(() => {
        mothershipTokenManager.removeAccessToken()

        addNotification(
          NOTIFICATION_KIND.ERROR,
          '로그인 실패',
          '아이디 또는 비밀번호를 확인해 주세요.'
        )
      })
      .finally(() => {
        closeBackdrop()
      })
  }

  return (
    <div className="mothership-login-view-frame">
      <div className="mothership-login-view-title">Your AI Accountant</div>
      <div className="mothership-login-view-input-frame">
        <div className="mothership-login-view-email-frame">
          <div className="mothership-login-view-email-title">아이디</div>
          <div className="mothership-login-view-email-input">
            <CarbonInputComponent
              changeCallbackFunc={setEmail}
              className="mothership-login-view-input-control"
              textInputProps={{
                id: 'email',
                labelText: '아이디',
                placeholder: '아이디를 입력해 주세요.',
                type: 'email'
              }}
            />
          </div>
          {/* <div
            className="mothership-login-view-email-forgot-email"
            onClick={() => {
              // eslint-disable-next-line no-console
              console.log('이메일을 잊으셨나요?')
            }}
          >
            이메일을 잊으셨나요?
          </div> */}
        </div>
        <div className="mothership-login-view-password-frame">
          <div className="mothership-login-view-password-title">비밀번호</div>
          <div className="mothership-login-view-password-input">
            <CarbonInputComponent
              changeCallbackFunc={setPassword}
              className="mothership-login-view-input-control"
              enterCallbackFunc={() => handleLoginCallbackFun()}
              textInputProps={{
                id: 'password',
                labelText: '비밀번호',
                placeholder: '비밀번호를 입력해 주세요.',
                type: 'password'
              }}
            />
          </div>
          {/* <div
            className="mothership-login-view-password-forgot-password"
            onClick={() => {
              // eslint-disable-next-line no-console
              console.log('비밀번호 재설정')
            }}
          >
            비밀번호 재설정
          </div> */}
        </div>
        <div className="mothership-login-view-login-button-frame">
          <Button
            className="mothership-login-view-login-button"
            disabled={isEmpty(email) || isEmpty(password)}
            size="middle"
            type="primary"
            onClick={() => handleLoginCallbackFun()}
          >
            로그인
          </Button>
          <div className="mothership-login-view-illust-frame">
            <img
              alt="Frame Illustration"
              src={process.env.PUBLIC_URL + '/resource/images/login/Frame_illust.svg'}
            />
          </div>
        </div>
      </div>

      <div className="mothership-login-view-footer-frame">
        <div className="mothership-login-view-footer-text">
          계정 생성 및 사용 권한은 귀하의 소속 법인 관리자에게 문의해 주세요.
          <div className="mothership-login-view-footer-text-inquiry" onClick={redirectHomepage}>
            도입 문의
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginViewComponent

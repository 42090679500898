import { Search, TextInput, type TextInputProps } from '@carbon/react'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import './_input.scss'

interface CarbonInputComponentProps {
  changeCallbackFunc?: (text: string) => void
  textInputProps: TextInputProps
  iconData?: React.ComponentType
  enterCallbackFunc?: () => void
  className?: string
  onBlurCallbackFun?: () => void
  arrowCallbackFunc?: (isUp: boolean) => void
}

const CarbonInputComponent: React.FC<CarbonInputComponentProps> = ({
  changeCallbackFunc,
  iconData,
  enterCallbackFunc,
  textInputProps,
  className,
  onBlurCallbackFun,
  arrowCallbackFunc
}) => {
  const [processCompositionEnter, setProcessCompositionEnter] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      setProcessCompositionEnter(event.nativeEvent.isComposing)
      enterCallbackFunc && enterCallbackFunc()
    }
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      if (arrowCallbackFunc) {
        arrowCallbackFunc(event.key === 'ArrowUp')
        return
      }

      event.stopPropagation()
      event.preventDefault()
    }
  }

  useEffect(() => {
    const currentRef = inputRef.current
    function handleClickOutside(): void {
      if (currentRef) {
        onBlurCallbackFun && onBlurCallbackFun()
      }
    }

    if (!currentRef) {
      return
    }
    currentRef.addEventListener('blur', handleClickOutside)
    return () => {
      currentRef?.removeEventListener('blur', handleClickOutside)
    }
  }, [inputRef, onBlurCallbackFun])

  const renderTextInput = () => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (processCompositionEnter) {
        return
      }
      changeCallbackFunc && changeCallbackFunc(event.target.value)
    }
    return (
      <TextInput
        hideLabel
        {...textInputProps}
        autoComplete="off"
        autoCorrect="off"
        className={classNames('carbon-text-input', className)}
        id="carbon-text-input"
        ref={inputRef}
        size="sm"
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    )
  }

  if (iconData !== undefined) {
    const handleChange = (input: HTMLInputElement) => {
      if (processCompositionEnter) {
        return
      }
      changeCallbackFunc && changeCallbackFunc(input.value)
    }

    return (
      <Search
        {...textInputProps}
        autoComplete="off"
        autoCorrect="off"
        className={classNames('carbon-text-input', className)}
        id="carbon-text-input"
        ref={inputRef}
        renderIcon={iconData}
        size="sm"
        onChange={(event) => handleChange(event.target)}
        onKeyDown={handleKeyDown}
      />
    )
  }

  return renderTextInput()
}
export default CarbonInputComponent

import { Axios } from 'axios'
import CryptoJS from 'crypto-js'
import SparkMD5 from 'spark-md5'

import { convertToDashSeparatedDateFromDateObject } from 'utils/date_utils'

async function computeMD5HashForBigFile(input: string | File): Promise<string> {
  return new Promise((resolve, reject) => {
    if (typeof input === 'string') {
      const md5 = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(input))
      const base64 = CryptoJS.enc.Base64.stringify(md5)
      resolve(base64)
    } else if (input instanceof File) {
      const chunkSize = 1024 * 1024 // 1MB 단위로 파일을 읽습니다.
      const blobSlice = File.prototype.slice
      const fileChunks = Math.ceil(input.size / chunkSize)
      let currentChunk = 0
      const spark = new SparkMD5.ArrayBuffer()

      const fileReader = new FileReader()

      fileReader.onload = (e) => {
        if (e.target && e.target.result) {
          const binaryString = e.target.result as ArrayBuffer
          spark.append(binaryString)

          currentChunk++

          if (currentChunk < fileChunks) {
            loadNextChunk()
          } else {
            const md5Hash = spark.end() // 최종 MD5 해시 계산
            const base64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Hex.parse(md5Hash))
            resolve(base64)
          }
        }
      }

      fileReader.onerror = () => {
        reject(new Error('파일 읽기 오류'))
      }

      const loadNextChunk = () => {
        const start = currentChunk * chunkSize
        const end = Math.min(start + chunkSize, input.size)
        const chunk = blobSlice.call(input, start, end)
        fileReader.readAsArrayBuffer(chunk)
      }

      loadNextChunk()
    } else {
      reject(new Error('Invalid input type'))
    }
  })
}

export async function computeMD5Hash(input: string | File): Promise<string> {
  // 대략 200mb 부터 binaryString으로 읽을 때 array length오류가 나서 그 이상부터는 chunk로 나눠서 읽도록 수정
  if (input instanceof File && input.size > 1024 * 1024 * 200) {
    return computeMD5HashForBigFile(input)
  }
  return new Promise((resolve, reject) => {
    if (typeof input === 'string') {
      const md5 = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(input))
      const base64 = CryptoJS.enc.Base64.stringify(md5)
      resolve(base64)
    } else if (input instanceof File) {
      const reader = new FileReader()
      reader.onload = (event) => {
        if (event.target && event.target.result) {
          const binaryString = event.target.result as string
          const md5 = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(binaryString))
          const base64 = CryptoJS.enc.Base64.stringify(md5)
          resolve(base64)
        } else {
          reject(new Error('Failed to read file'))
        }
      }

      reader.onerror = () => {
        reject(new Error('File reading error'))
      }

      reader.readAsBinaryString(input)
    } else {
      reject(new Error('Invalid input type'))
    }
  })
}

export async function readFileAsByteArray(file: File): Promise<Uint8Array> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event) => {
      const arrayBuffer = event.target?.result
      if (arrayBuffer instanceof ArrayBuffer) {
        resolve(new Uint8Array(arrayBuffer))
      } else {
        reject(new Error('FileReader result is not an ArrayBuffer'))
      }
    }

    reader.onerror = (error) => {
      reject(error)
    }

    reader.readAsArrayBuffer(file)
  })
}

// NOTE (hyeonseok 23.05.02):
// chatGPT code
export async function readFileAsBlob(file: File): Promise<Blob> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const dataUrl = reader.result as string
      const byteString = atob(dataUrl.split(',')[1])
      const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0]
      const arrayBuffer = new ArrayBuffer(byteString.length)
      const ia = new Uint8Array(arrayBuffer)

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }

      resolve(new Blob([arrayBuffer], { type: mimeString }))
    }

    reader.onerror = (error) => {
      reject(error)
    }

    reader.readAsDataURL(file)
  })
}

export function getFileNameWithHeaderData(
  headers: any,
  defaultFileName: string,
  fileType: string
): string {
  const lowerCaseHeaderName = 'Content-Disposition'.toLowerCase()
  const contentDisposition = Object.entries(headers).find(
    ([key, value]) => key.toLowerCase() === lowerCaseHeaderName
  )?.[1]

  if (contentDisposition && typeof contentDisposition === 'string') {
    // NOTE (hyeonseok 23.05.05):
    // filename*=UTF-8 로 시작하는 위치를 찾아 파일명 얻어옴
    // const filenameRegex = /filename\*=[^']+''([^;]+)/
    const filenameRegex = /filename\*=UTF-8''([^;]+)/
    const fileNames = contentDisposition.match(filenameRegex)
    if (!fileNames || fileNames.length < 2) {
      return `${defaultFileName}_${convertToDashSeparatedDateFromDateObject(
        new Date()
      )}.${fileType}`
    }

    return decodeURIComponent(fileNames[1])
  }

  return `${defaultFileName}_${convertToDashSeparatedDateFromDateObject(new Date())}.${fileType}`
}

export function downloadFileWithArrayBuffer(
  arrayBuffer: ArrayBuffer,
  headers: any,
  defaultFileName: string,
  fileType: 'zip' | 'xlsx' | 'pdf'
): void {
  const fileName = getFileNameWithHeaderData(headers, defaultFileName, fileType)
  // ArrayBuffer를 사용하여 Blob 객체 생성
  let mimeFileType = ''
  switch (fileType) {
    case 'zip':
      mimeFileType = 'application/zip'
      break
    case 'pdf':
      mimeFileType = 'application/pdf'
      break
    case 'xlsx':
    default:
      mimeFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      break
  }
  const blob = new Blob([arrayBuffer], {
    type: mimeFileType
  })

  // Blob 객체를 가리키는 URL을 생성
  const downloadUrl = URL.createObjectURL(blob)

  // 다운로드 링크를 생성하고 클릭 이벤트 트리거
  const link = document.createElement('a')
  link.href = downloadUrl
  link.download = fileName
  document.body.appendChild(link)
  link.click()

  // 다운로드가 완료되면 링크 제거
  setTimeout(() => {
    document.body.removeChild(link)
    URL.revokeObjectURL(downloadUrl)
  }, 100)
}

// 파일 확장자를 xlsx로 바꿈
export function replaceExtension(filename: string, extension: string): string {
  const lastDotIndex = filename.lastIndexOf('.')
  if (lastDotIndex === -1) {
    return `${filename}.${extension}`
  }
  return `${filename.slice(0, lastDotIndex)}.${extension}`
}

export function downloadFileWithURL(url: string, fileName: string): void {
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  // link.download = fileName
  link.style.display = 'none'
  document.body.appendChild(link)
  link.click()

  // 다운로드가 완료되면 링크 제거
  setTimeout(() => {
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }, 100)
}

export async function downloadFile(fileURL: string, fileName: string): Promise<boolean> {
  try {
    const response = await fetch(fileURL)
    const blob = await response.blob()
    const url = URL.createObjectURL(blob)

    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName) // 원하는 파일 이름을 지정하세요
    link.style.display = 'none'
    document.body.appendChild(link)
    link.click()

    setTimeout(() => {
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    }, 100)
  } catch {
    throw new Error('Error while downloading the file')
  }
  return true
}

const wordExtensions = ['doc', 'docx', 'docm', 'dot', 'dotx', 'dotm', 'docb']
const excelExtensions = ['xls', 'xlsx', 'xlsm', 'xltx', 'xltm', 'xlsb', 'xlam']
const powerPointExtensions = [
  'ppt',
  'pptx',
  'pptm',
  'potx',
  'potm',
  'ppam',
  'ppsx',
  'ppsm',
  'sldx',
  'sldm'
]
const hanguleExtensions = ['hwp', 'hwpx']

const htmlExtensions = ['html', 'htm']

const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg', 'svgz', 'tiff']

export function getFileIconExtension(fileName: string): string {
  const index = fileName.lastIndexOf('.')
  const extension = fileName.substring(index + 1).toLowerCase()
  if (wordExtensions.includes(extension)) {
    return 'docx'
  }
  if (excelExtensions.includes(extension)) {
    return 'xlsx'
  }
  if (powerPointExtensions.includes(extension)) {
    return 'pptx'
  }
  if (hanguleExtensions.includes(extension)) {
    return 'hwp'
  }
  if (imageExtensions.includes(extension)) {
    return 'jpg'
  }
  if (htmlExtensions.includes(extension)) {
    return 'html'
  }
  return extension
}

type FileSizeUnit = 'bytes' | 'KB' | 'MB' | 'GB'

/**
 * file size를 input으로 받아서 단위를 붙여서 return
 * @param fileSizeInBytes file size
 * @param minDegree 최소 단위
 * @param withoutDecimal 소수점 제외 여부
 */
export function getFileSizeWithUnit(
  fileSizeInBytes: number,
  minDegree?: FileSizeUnit,
  withoutDecimal?: boolean
): string {
  const units = ['bytes', 'KB', 'MB', 'GB']
  let fileSize = fileSizeInBytes
  let unitIndex = 0
  while (fileSize >= 1024 && unitIndex < units.length - 1) {
    fileSize /= 1024
    unitIndex++
  }
  if (minDegree && units.indexOf(minDegree) > unitIndex) {
    fileSize *= Math.pow(1 / 1024, units.indexOf(minDegree) - unitIndex)
    unitIndex = units.indexOf(minDegree)
  }

  return `${withoutDecimal ? Math.floor(fileSize) : fileSize.toFixed(2)} ${units[unitIndex]}`
}

export enum STORAGE_SERVER_TYPE {
  // eslint-disable-next-line no-unused-vars
  AWS_S3 = 'S3',
  // eslint-disable-next-line no-unused-vars
  AZURE_BLOB = 'AZURE_BLOB'
}

function getPutToStorageHeader(
  storageType: STORAGE_SERVER_TYPE,
  MD5: string
): { [key: string]: string } {
  // 헤더 설정
  let headers: { [key: string]: string } = {
    // 공통으로 사용하는 헤더
    'Content-Type': 'multipart/form-data'
  }

  if (storageType === STORAGE_SERVER_TYPE.AWS_S3) {
    headers = {
      ...headers,
      'Content-MD5': MD5 // Base64 인코딩된 MD5 해시값
    }
  } else {
    // STORAGE_SERVER_TYPE.AZURE_BLOB
    headers = {
      ...headers,
      'x-ms-blob-type': 'BlockBlob'
    }
  }

  return headers
}

function getPutToStorageTarget(storageType: STORAGE_SERVER_TYPE, file: File): File | FormData {
  if (storageType === STORAGE_SERVER_TYPE.AWS_S3) {
    return file
  }

  // STORAGE_SERVER_TYPE.AZURE_BLOB
  const formData = new FormData()
  formData.append('file', file)
  return formData
}

async function uploadTargetToStorage(
  target: File | FormData,
  url: string,
  axios: Axios,
  headers: { [key: string]: string }
): Promise<boolean> {
  try {
    const response = await axios.put(url, target, { headers, withCredentials: false })
    return response.status === 200 || response.status === 201
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error uploading file to Storage:', error)
    return false
  }
}

export async function putFileToStorageServer(
  file: File,
  MD5: string,
  url: string,
  axios: Axios,
  storageType: STORAGE_SERVER_TYPE = STORAGE_SERVER_TYPE.AWS_S3
): Promise<boolean> {
  const headers = getPutToStorageHeader(storageType, MD5)

  const target = getPutToStorageTarget(storageType, file)

  return await uploadTargetToStorage(target, url, axios, headers)
}

function toBase64(str: string): string {
  if (typeof window !== 'undefined' && window.btoa) {
    return window.btoa(str)
  } else if (typeof Buffer !== 'undefined') {
    return Buffer.from(str).toString('base64')
  }
  throw new Error('No base64 encoding function available')
}

export async function uploadFileInChunksForAzure(
  file: File,
  MD5: string,
  url: string,
  axios: Axios,
  chunkSize: number
): Promise<string[]> {
  const CHUNK_SIZE = chunkSize // 120KB chunks
  const blockIds = []
  const failedChunks: number[] = []

  for (let i = 0; i < file.size; i += CHUNK_SIZE) {
    const chunk = file.slice(i, Math.min(i + CHUNK_SIZE, file.size))
    const blockId = toBase64(String(i).padStart(6, '0')) // Convert to base64
    blockIds.push(blockId)
    await axios
      .put(url, chunk, {
        headers: {
          'x-ms-blob-type': 'BlockBlob'
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(`Error uploading chunk starting at byte ${i}:`, error)
        failedChunks.push(i)
      })
  }

  if (failedChunks.length > 0) {
    throw new Error(`Failed to upload chunks starting at bytes: ${failedChunks.join(', ')}`)
  }

  return blockIds
}

export async function isPDFFile(file: File): Promise<boolean> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = (e) => {
      const result = e.target?.result
      if (result) {
        const bytes = new Uint8Array(result as ArrayBuffer)
        const pdfSignature = String.fromCharCode(...bytes.subarray(0, 5))
        resolve(pdfSignature === '%PDF-')
      } else {
        reject(new Error('Failed to read file'))
      }
    }

    reader.onerror = () => {
      reject(new Error('Error reading file'))
    }

    reader.readAsArrayBuffer(file.slice(0, 5)) // 첫 5바이트만 읽습니다.
  })
}

export function downloadArrayBuffer(source: ArrayBuffer, fileName: string): void {
  const blob = new Blob([source], { type: 'application/octet-stream' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  window.URL.revokeObjectURL(url)
  document.body.removeChild(a)
}

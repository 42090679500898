import React, { useEffect, useRef, useState } from 'react'

import CarbonIconWrapperComponent from 'components/icon/CarbonIconWrapperComponent'
import ScrollBarComponent, {
  ScrollBarRefType,
  ScrollBarTheme
} from 'components/scroll/ScrollBarComponent'

import './_scroll.scss'

// *** ScrollFloatingControlComponentProps ***
// [ children ]
// React.ReactNode

// [ useHorizontalControls ]
// 가로 스크롤 컨트롤 사용 여부
// default true

// [ useVerticalControls ]
// 세로 스크롤 컨트롤 사용 여부
// default true

// [ minWidth / minHeight ]
// 기본적으로 부모 element의 100% width/height 사용
// 단위 : px

// [ scrollBarTheme ]
// 스크롤바의 테마를 설정

// eslint-disable-next-line no-unused-vars
enum ScrollDirection {
  // eslint-disable-next-line no-unused-vars
  VERTICAL = 'vertical',
  // eslint-disable-next-line no-unused-vars
  HORIZONTAL = 'horizontal'
}

type ScrollFloatingControlComponentProps = {
  children: React.ReactNode
  minWidth: number
  minHeight: number
  useHorizontalControls?: boolean
  useVerticalControls?: boolean
  scrollBarTheme?: ScrollBarTheme
  scrollAmount?: number
}

const ScrollFloatingControlComponent: React.FC<ScrollFloatingControlComponentProps> = ({
  children,
  minWidth,
  minHeight,
  useHorizontalControls = true,
  useVerticalControls = true,
  scrollBarTheme = ScrollBarTheme.DARK,
  scrollAmount
}) => {
  const scrollBarRef = useRef<ScrollBarRefType>(null)
  const [showTopButton, setShowTopButton] = useState(false)
  const [showBottomButton, setShowBottomButton] = useState(true)
  const [showLeftButton, setShowLeftButton] = useState(false)
  const [showRightButton, setShowRightButton] = useState(true)

  const FLOATING_CONTROL_BUTTON_ICON_SIZE = 16

  useEffect(() => {
    const scrollBar = scrollBarRef.current
    if (!scrollBar) {
      return
    }

    handleScrollFrame(scrollBar.getValues())
  }, [children])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleScrollFrame = (values: any) => {
    const { scrollTop, scrollHeight, clientHeight, scrollLeft, scrollWidth, clientWidth } = values
    const epsilon = 1 // 1 픽셀의 여유를 둠

    setShowTopButton(scrollTop > epsilon)
    setShowBottomButton(scrollTop < scrollHeight - clientHeight - epsilon)
    setShowLeftButton(scrollLeft > epsilon)
    setShowRightButton(scrollLeft < scrollWidth - clientWidth - epsilon)
  }

  // NOTE (hyeonseok 24.06.06):
  // react-custom-scrollbars-2에서는 view를 제공하지 않음 (index.d.ts에서 내보내고 있지 않음)
  // 따라서 view.scrollTo({top: 0, behavior: 'smooth'})와 같이 scrollTo 사용 불가
  //  스크롤이 자연스럽게 이동될 수 있도록 animation frame 을 사용하도록 함
  const smoothScroll = (start: number, end: number, direction: ScrollDirection) => {
    const duration = 200 // duration of the scroll in ms
    const startTime = performance.now()

    const animateScroll = (currentTime: number) => {
      const elapsedTime = currentTime - startTime
      const progress = Math.min(elapsedTime / duration, 1)
      const easing = (t: number) => t * (2 - t) // ease-out function

      if (scrollBarRef.current) {
        if (direction === ScrollDirection.VERTICAL) {
          const newY = start + (end - start) * easing(progress)
          scrollBarRef.current.scrollTop(newY)
        } else {
          const newX = start + (end - start) * easing(progress)
          scrollBarRef.current.scrollLeft(newX)
        }
      }

      if (progress < 1) {
        requestAnimationFrame(animateScroll)
      }
    }

    requestAnimationFrame(animateScroll)
  }
  const scrollToTop = () => {
    if (!scrollBarRef.current) {
      return
    }

    const start = scrollBarRef.current.getScrollTop()
    smoothScroll(start, 0, ScrollDirection.VERTICAL)
  }

  const scrollToBottom = () => {
    if (!scrollBarRef.current) {
      return
    }

    const start = scrollBarRef.current.getScrollTop()
    const end =
      scrollAmount !== undefined
        ? start + scrollAmount
        : scrollBarRef.current.getScrollHeight() - scrollBarRef.current.getClientHeight()
    smoothScroll(start, end, ScrollDirection.VERTICAL)
  }

  const scrollToLeft = () => {
    if (!scrollBarRef.current) {
      return
    }

    const start = scrollBarRef.current.getScrollLeft()
    smoothScroll(start, 0, ScrollDirection.HORIZONTAL)
  }

  const scrollToRight = () => {
    if (!scrollBarRef.current) {
      return
    }

    const start = scrollBarRef.current.getScrollLeft()
    const end =
      scrollAmount !== undefined
        ? start + scrollAmount
        : scrollBarRef.current.getScrollWidth() - scrollBarRef.current.getClientWidth()
    smoothScroll(start, end, ScrollDirection.HORIZONTAL)
  }

  const makeVerticalDirectionControls = () => {
    if (!useVerticalControls) {
      return null
    }

    return (
      <>
        {showTopButton && (
          <div className="scroll-floating-control-button-vertical-top" onClick={scrollToTop}>
            <CarbonIconWrapperComponent
              iconData={{
                imageString: 'ChevronUp',
                isSvg: false,
                width: FLOATING_CONTROL_BUTTON_ICON_SIZE,
                height: FLOATING_CONTROL_BUTTON_ICON_SIZE
              }}
            />
          </div>
        )}
        {showBottomButton && (
          <div className="scroll-floating-control-button-vertical-bottom" onClick={scrollToBottom}>
            <CarbonIconWrapperComponent
              iconData={{
                imageString: 'ChevronDown',
                isSvg: false,
                width: FLOATING_CONTROL_BUTTON_ICON_SIZE,
                height: FLOATING_CONTROL_BUTTON_ICON_SIZE
              }}
            />
          </div>
        )}
      </>
    )
  }

  const makeHorizontalDirectionControls = () => {
    if (!useHorizontalControls) {
      return null
    }

    return (
      <>
        {showLeftButton && (
          <div className="scroll-floating-control-button-horizontal-left" onClick={scrollToLeft}>
            <CarbonIconWrapperComponent
              iconData={{
                imageString: 'ChevronLeft',
                isSvg: false,
                width: FLOATING_CONTROL_BUTTON_ICON_SIZE,
                height: FLOATING_CONTROL_BUTTON_ICON_SIZE
              }}
            />
          </div>
        )}
        {showRightButton && (
          <div className="scroll-floating-control-button-horizontal-right" onClick={scrollToRight}>
            <CarbonIconWrapperComponent
              iconData={{
                imageString: 'ChevronRight',
                isSvg: false,
                width: FLOATING_CONTROL_BUTTON_ICON_SIZE,
                height: FLOATING_CONTROL_BUTTON_ICON_SIZE
              }}
            />
          </div>
        )}
      </>
    )
  }

  return (
    <div className="scroll-floating-control-frame">
      <ScrollBarComponent
        minHeight={minHeight}
        minWidth={minWidth}
        scrollBarRef={scrollBarRef}
        theme={scrollBarTheme}
        onScrollFrame={handleScrollFrame}
      >
        {children}
      </ScrollBarComponent>
      {makeVerticalDirectionControls()}
      {makeHorizontalDirectionControls()}
    </div>
  )
}

export default ScrollFloatingControlComponent

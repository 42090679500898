import { CustomHeaderProps } from 'ag-grid-react'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import CarbonIconWrapperComponent from 'components/icon/CarbonIconWrapperComponent'
import { CarbonAgGridCustomHeaderProps } from 'components/table/column/CarbonColumnDefTypes'

interface CustomAgGridHeaderComponentProps extends CustomHeaderProps {
  value: React.ReactNode
  customHeaderProps?: CarbonAgGridCustomHeaderProps
}

const CustomAgGridHeaderComponent: React.FC<CustomAgGridHeaderComponentProps> = ({
  value,
  setSort,
  column,
  customHeaderProps
}) => {
  const getInitialSortState = () => {
    const sort = column?.getSort()
    if (sort === undefined) {
      return null
    }

    return sort as 'asc' | 'desc' | null
  }

  const [sortState, setSortState] = useState<'asc' | 'desc' | null>(getInitialSortState())

  const isSortable = customHeaderProps?.isSortable ?? false

  useEffect(() => {
    const onSortChanged = () => {
      const sort = column?.getSort()
      if (sort === undefined) {
        setSortState(null)
        return
      }
      setSortState(sort as 'asc' | 'desc' | null)
    }

    column?.addEventListener('sortChanged', onSortChanged)
    return () => column?.removeEventListener('sortChanged', onSortChanged)
  }, [column])

  const onSortClicked = () => {
    if (!isSortable) {
      return
    }
    let nextSort: 'asc' | 'desc' | null
    if (sortState === 'asc') nextSort = 'desc'
    else if (sortState === 'desc') nextSort = null
    else nextSort = 'asc'

    setSort(nextSort)
  }

  const getSortIcon = () => {
    if (sortState === 'asc') return 'ArrowUp'
    else if (sortState === 'desc') return 'ArrowDown'
    else return 'ArrowDown'
  }

  return (
    <div
      className={classNames('carbon-table-custom-header-frame', {
        'carbon-table-custom-header-frame-sortable': isSortable
      })}
      onClick={onSortClicked}
    >
      {value}
      {isSortable && !!sortState && (
        <CarbonIconWrapperComponent
          iconData={{
            imageString: getSortIcon(),
            width: 24,
            height: 24
          }}
        />
      )}
    </div>
  )
}

export default CustomAgGridHeaderComponent

import React from 'react'

import { CarbonCommonAgGridCellRendererProps } from 'components/table/control/CarbonAgGridTypes'

interface CarbonCustomAgGridCellRendererComponentProps extends CarbonCommonAgGridCellRendererProps {
  value: React.ReactNode
  autoHeight?: boolean
}

const CarbonCustomAgGridCellRendererComponent: React.FC<
  CarbonCustomAgGridCellRendererComponentProps
> = ({ value, eGridCell, autoHeight = false }) => {
  return (
    <div
      className="carbon-table-custom-cell"
      style={{
        height: autoHeight ? undefined : eGridCell.clientHeight,
        width: eGridCell.clientWidth
      }}
    >
      {value}
    </div>
  )
}

export default CarbonCustomAgGridCellRendererComponent

export interface TokenCookieOptions {
  secure?: boolean
  sameSite?: 'Strict' | 'Lax' | 'None'
  domain?: string
  path?: string
}

const DEFAULT_ALLOWED_DOMAIN = '.accio.kr'

class MothershipTokenManager {
  // eslint-disable-next-line no-use-before-define
  private static instance: MothershipTokenManager
  private readonly TOKEN_NAME: string = 'access_token'
  private readonly DEFAULT_EXPIRY_HOURS: number = 24
  private readonly TOKEN_CHANGE_EVENT = 'mothership:tokenChange'
  private readonly ALLOWED_DOMAIN: string =
    process.env.REACT_APP_ALLOWED_DOMAIN || DEFAULT_ALLOWED_DOMAIN

  public static getInstance(): MothershipTokenManager {
    if (!MothershipTokenManager.instance) {
      MothershipTokenManager.instance = new MothershipTokenManager()
    }
    return MothershipTokenManager.instance
  }

  // private getDomainFromLocation(): string {
  //   const hostname = window.location.hostname
  //   // localhost인 경우 domain 설정을 하지 않음
  //   if (hostname === 'localhost') {
  //     return ''
  //   }
  //   // IP 주소인 경우 그대로 사용
  //   if (/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(hostname)) {
  //     return hostname
  //   }
  //   // 서브도메인이 있는 경우 메인 도메인만 추출
  //   const domainParts = hostname.split('.')
  //   if (domainParts.length > 2) {
  //     return '.' + domainParts.slice(-2).join('.')
  //   }
  //   return hostname
  // }

  // JWT 토큰을 파싱하는 private 메서드 추가
  private parseJwt(token: string): any {
    try {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )

      return JSON.parse(jsonPayload)
    } catch (error) {
      throw new Error('Invalid token format')
    }
  }

  public addTokenChangeListener(callback: (token: string | null) => void): () => void {
    const handler = (event: CustomEvent) => callback(event.detail.token)
    window.addEventListener(this.TOKEN_CHANGE_EVENT, handler as EventListener)
    return () => window.removeEventListener(this.TOKEN_CHANGE_EVENT, handler as EventListener)
  }

  private notifyTokenChange(token: string | null): void {
    const event = new CustomEvent(this.TOKEN_CHANGE_EVENT, {
      detail: { token }
    })
    window.dispatchEvent(event)
  }

  public setAccessToken(token: string, options: TokenCookieOptions = {}): void {
    // JWT 토큰에서 만료 시간 추출
    const tokenPayload = this.parseJwt(token)
    if (!tokenPayload.exp) {
      throw new Error('Token does not contain expiration time')
    }

    const expiryDate = new Date(tokenPayload.exp * 1000) // JWT의 exp는 초 단위이므로 밀리초로 변환

    const defaultOptions: Required<TokenCookieOptions> = {
      secure: window.location.protocol === 'https:',
      sameSite: 'None', // cross-site 접근 허용
      path: '/',
      domain: this.ALLOWED_DOMAIN // 서브도메인과 공유
    }

    const mergedOptions: Required<TokenCookieOptions> = {
      ...defaultOptions,
      ...options
    }

    let cookieString = `${this.TOKEN_NAME}=${token}; expires=${expiryDate.toUTCString()}`

    if (mergedOptions.secure) {
      cookieString += '; Secure'
    }

    if (mergedOptions.sameSite) {
      cookieString += `; SameSite=${mergedOptions.sameSite}`
    }

    if (mergedOptions.path) {
      cookieString += `; Path=${mergedOptions.path}`
    }

    if (mergedOptions.domain) {
      cookieString += `; Domain=${mergedOptions.domain}`
    }

    document.cookie = cookieString
    this.notifyTokenChange(token)
  }

  public getAccessToken(): string | null {
    const cookies = document.cookie.split(';')
    for (const cookie of cookies) {
      const [name, value] = cookie.trim().split('=')
      if (name === this.TOKEN_NAME) {
        return value
      }
    }
    return null
  }

  public removeAccessToken(): void {
    const options: Required<TokenCookieOptions> = {
      secure: window.location.protocol === 'https:',
      sameSite: 'None', // cross-site 접근 허용
      path: '/',
      domain: this.ALLOWED_DOMAIN // 서브도메인과 공유
    }

    // eslint-disable-next-line max-len
    document.cookie = `${this.TOKEN_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${options.path}; domain=${options.domain}`
    this.notifyTokenChange(null)
  }

  public isTokenExpired(): boolean {
    const token = this.getAccessToken()
    if (!token) return true

    try {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )

      const payload = JSON.parse(jsonPayload) as { exp: number }
      return payload.exp * 1000 < Date.now()
    } catch {
      return true
    }
  }
}

export const mothershipTokenManager = MothershipTokenManager.getInstance()

/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash'

const URL_PREFIX = '/v1/mothership'

type URLType = string | { [key: string]: URLType }

interface MothershipURLObj {
  [key: string]: URLType
}

const mothershipURLObj: MothershipURLObj = {
  USER: {
    UNREGISTER: '/user/unregister',
    LOGIN: '/user/login',
    LOGOUT: '/user/logout',
    REFRESH: '/user/refresh',
    REGISTER: '/user/register'
  },
  SAMPLE: {
    TOKEN_AUTH_TEST: '/sample/tokenAuthTest'
  }
}

const getRecursiveMapValues = (value: any, callbackFunc: (url: string) => string): any => {
  return _.mapValues(value, (recurValue) => {
    if (_.isObject(recurValue)) {
      return getRecursiveMapValues(recurValue, callbackFunc)
    }
    return callbackFunc(recurValue)
  })
}

const MOTHERSHIP_URL = getRecursiveMapValues(mothershipURLObj, (url: any) => URL_PREFIX + url)

export { MOTHERSHIP_URL }

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { Column, GridApi, IRowNode } from 'ag-grid-community'
import { CustomCellEditorProps, useGridCellEditor } from 'ag-grid-react'
import React, { useState, forwardRef, useEffect, useCallback } from 'react'

import CarbonInputComponent from 'components/input/CarbonInputComponent'
import {
  checkValueIsUnique,
  getAllRowData,
  showFlashRow,
  showFlashCellsSimple
} from 'components/table/util/CarbonTableUtil'

export interface CarbonBasicAgGridCellEditorComponentProps extends CustomCellEditorProps {
  // unique value check를 수행할지 여부
  checkUniqueValueInColumn?: boolean
  // input 값 validation check를 위한 callback
  isValidateCheckCallbackFunc?: (
    input: string,
    node: IRowNode,
    column: Column,
    api: GridApi
  ) => boolean
  // input 값이 최종적으로 Cell에 입력되기 전애 보정하기 위한 callback
  refinedInputValueCallbackFunc?: (input: string, node: IRowNode, column: Column) => string
  // input 값이 변경될 때마다 호출되는 callback
  changeInputValueFilterCallbackFunc?: (input: string, node: IRowNode, column: Column) => string
  moveNextAfterEdit?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CarbonBasicAgGridCellEditorComponent = forwardRef<
  any,
  CarbonBasicAgGridCellEditorComponentProps
>((props, ref) => {
  const {
    value,
    node,
    column,
    api,
    eGridCell,
    checkUniqueValueInColumn,
    isValidateCheckCallbackFunc,
    refinedInputValueCallbackFunc,
    changeInputValueFilterCallbackFunc,
    moveNextAfterEdit = true,
    onValueChange,
    initialValue,
    eventKey
  } = props

  // text input value
  const [inputValue, setInputValue] = useState(initialValue)

  const [inputSize, setInputSize] = useState({
    width: eGridCell.clientWidth,
    height: eGridCell.parentElement?.clientHeight || eGridCell.clientHeight
  })

  const isCancelBeforeStart = useCallback(() => {
    console.log('isCancelBeforeStart')
    return false
  }, [eventKey])

  const isValidateInput = () => {
    // unique check를 수행할지 여부
    if (
      checkUniqueValueInColumn &&
      !checkValueIsUnique(
        // 현재 자기 자신의 row data를 제외하고 전부 검사
        getAllRowData(api)
          .filter((rowData) => rowData.rowIndex !== node.rowIndex)
          .map((rowData) => rowData.data),
        column.getColId(),
        inputValue
      )
    ) {
      // unique check를 통과하지 못한 경우
      // NOTE (hyeonseok 23.09.05): callback function 필요할 수 있음
      showFlashRow({ api, rowNodes: [node], targetElement: eGridCell, flashType: 'error' })

      return false
    }

    // validation check를 수행할지 여부
    if (
      isValidateCheckCallbackFunc &&
      !isValidateCheckCallbackFunc(inputValue, node, column, api)
    ) {
      // validation check를 통과하지 못한 경우
      showFlashRow({ api, rowNodes: [node], targetElement: eGridCell, flashType: 'error' })
      return false
    }

    return true
  }

  // Gets called once when editing is finished (eg if Enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  const isCancelAfterEnd = useCallback(() => {
    console.log('isCancelAfterEnd')
    if (!isValidateInput()) {
      return true
    }
    return false
  }, [inputValue, value])

  useGridCellEditor({ isCancelBeforeStart, isCancelAfterEnd })
  useEffect(() => {
    const inputElement = eGridCell.getElementsByClassName('cds--text-input')[0] as HTMLInputElement
    inputElement?.focus()
    setInputSize({
      width: eGridCell.clientWidth,
      height: eGridCell.parentElement?.clientHeight || eGridCell.clientHeight
    })
  }, [])

  const handleInputChange = (input: string) => {
    const result = changeInputValueFilterCallbackFunc
      ? changeInputValueFilterCallbackFunc(input, node, column)
      : input

    // 입력값 변경 필터링
    setInputValue(result)
    onValueChange(result)
  }

  // Tab, Enter 입력 시 자동으로 입력이 종료되지 않도록 key down을 받고, unique 체크를 수행
  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key !== 'Enter') {
      // 종료 관련된 key가 아니면 무시
      return
    }

    if (event.key === 'Enter' && event.shiftKey) {
      setInputValue((prev: string) => prev + '\n')
      event.preventDefault()
      return
    }

    // enter 입력 시 , 편집 종료 및 아래 셀로 이동
    // 한글의 경우 Composition 확인 필요
    if (event.nativeEvent.isComposing) {
      return
    }

    const currentCell = api.getFocusedCell()
    if (currentCell === null) {
      return
    }
    const rowIndex = currentCell.rowIndex + (event.shiftKey ? -1 : 1)
    const colKey = currentCell.column.getColId()

    // selection range를 제거
    api.clearRangeSelection()
    // 현재 cell의 editing 종료
    api.stopEditing()

    // 다음 cell의 editing 시작
    moveNextAfterEdit &&
      api.startEditingCell({
        rowIndex,
        colKey
      })
  }

  const handleOnblur = () => {
    // if (isValidateInput()) {
    //   api.stopEditing()
    // }
  }
  return (
    <div
      className="carbon-table-input"
      style={{
        width: inputSize.width,
        height: inputSize.height
      }}
    >
      <CarbonInputComponent
        changeCallbackFunc={handleInputChange}
        textInputProps={{
          id: '',
          labelText: '',
          value: inputValue,
          size: 'sm'
        }}
        onBlurCallbackFun={() => handleOnblur()}
      />
    </div>
  )
})

CarbonBasicAgGridCellEditorComponent.displayName = 'CarbonAgGridCellEditorComponent'

export default CarbonBasicAgGridCellEditorComponent

import { ToastNotification } from '@carbon/react'
import { nanoid } from 'nanoid'
import React, { ReactNode, createContext, useCallback, useContext, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import LoadingOverlayComponent from 'components/loading/LoadingOverlayComponent'
import { notEmpty } from 'utils/common_utils'

import './_notification.scss'

export const NotificationProvideType = {
  USE_ALL: 'USE_ALL',
  USE_NOTIFICATION: 'USE_NOTIFICATION',
  USE_BACKDROP: 'USE_BACKDROP'
} as const

export type NotificationProvideType =
  typeof NotificationProvideType[keyof typeof NotificationProvideType]

// 기본 제공 옵션
export const NOTIFICATION_KIND = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
  // add options
} as const

export type NOTIFICATION_KIND = typeof NOTIFICATION_KIND[keyof typeof NOTIFICATION_KIND]

export interface NotificationContent {
  title?: string
  description?: string | ReactNode
  // add options
}

interface Notification {
  id: number
  title: string
  subtitle: string
  kind: NOTIFICATION_KIND
  caption: string
  lowContrast: boolean
}

interface NotificationContextProps {
  expandNotification: boolean
  setExpandNotification: (expandNotification: boolean) => void
  addNotification: (type: NOTIFICATION_KIND, title: string, subtitle: string) => void
  showBackdrop: (backdropText: string) => void
  closeBackdrop: () => void
  setIndividualBackdrop: (backdropText: string) => string
  removeIndividualBackdrop: (key: string) => void
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined)

export const useMainNotification = () => {
  const context = useContext(NotificationContext)
  if (!context) {
    throw new Error('useMainNotification must be used within a MainNotificationProvider')
  }
  return context
}

interface MainNotificationProviderProps {
  children: ReactNode
  timer?: number
}

const MainNotificationProvider: React.FC<MainNotificationProviderProps> = ({
  children,
  timer = 5000
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [backdropText, setBackdropText] = useState<string | undefined>(undefined)
  const [individualBackdropMap, setIndividualBackdropMap] = useState<Map<string, string>>(new Map())

  const [expandNotification, setExpandNotification] = useState(true)

  const addNotification = useCallback(
    (kind: NOTIFICATION_KIND, title: string, subtitle: string) => {
      const newNotification: Notification = {
        id: Date.now(),
        title,
        subtitle,
        kind,
        caption: '',
        lowContrast: true
      }

      setNotifications((prevNotifications) => [...prevNotifications, newNotification])

      // 자동으로 5초 후에 알림 제거
      setTimeout(() => {
        removeNotification(newNotification.id)
      }, timer)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const removeNotification = useCallback((id: number) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    )
  }, [])

  const showBackdrop = (text: string) => {
    setBackdropText(text)
  }

  const closeBackdrop = () => {
    setBackdropText(undefined)
  }

  const setIndividualBackdrop = (inputBackdropText: string): string => {
    const key = nanoid()

    setIndividualBackdropMap((prevMap) => {
      const newBackdropMap = new Map(prevMap)
      newBackdropMap.set(key, inputBackdropText)
      return newBackdropMap
    })

    return key
  }

  const removeIndividualBackdrop = (key: string) => {
    setIndividualBackdropMap((prevMap) => {
      const newBackdropMap = new Map(prevMap)
      newBackdropMap.delete(key)

      return newBackdropMap
    })
  }

  return (
    <NotificationContext.Provider
      value={{
        expandNotification,
        setExpandNotification,
        addNotification,
        showBackdrop,
        closeBackdrop,
        setIndividualBackdrop,
        removeIndividualBackdrop
      }}
    >
      <div className="notification-item-frame">
        <TransitionGroup component={null}>
          {notifications.map((notification) => (
            <CSSTransition classNames="notification-item" key={notification.id} timeout={500}>
              <ToastNotification
                caption={notification.caption}
                key={notification.id}
                kind={notification.kind}
                lowContrast={notification.lowContrast}
                subtitle={notification.subtitle}
                title={notification.title}
                onCloseButtonClick={() => removeNotification(notification.id)}
              />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
      {notEmpty(backdropText) && <LoadingOverlayComponent open text={backdropText} />}
      {individualBackdropMap.size > 0 && (
        <LoadingOverlayComponent
          open
          text={Array.from(individualBackdropMap.values()).at(individualBackdropMap.size - 1)}
        />
      )}
      {children}
    </NotificationContext.Provider>
  )
}

export default MainNotificationProvider

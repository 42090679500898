import { Checkbox, type CheckboxProps } from '@carbon/react'
import React from 'react'

interface CarbonCheckboxProp {
  checked: boolean
  onChangeCallbackFun: (checked: boolean) => void
  checkboxProps: CheckboxProps
}

const CarbonCheckboxComponent: React.FC<CarbonCheckboxProp> = ({
  checked,
  onChangeCallbackFun,
  checkboxProps
}) => {
  // rerender가 제대로 되지 않기 때문에 key를 적용함
  const key = checked ? 'checked' : 'unchecked'
  return (
    <Checkbox
      hideLabel
      checked={checked}
      key={key}
      {...checkboxProps}
      defaultChecked={checked}
      onChange={(evt, newChecked) => onChangeCallbackFun(newChecked.checked)}
    />
  )
}

export default CarbonCheckboxComponent

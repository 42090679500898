import { createAxios } from 'api/Axios'
import { MOTHERSHIP_URL } from 'api/MothershipURL'

const USER_URL = MOTHERSHIP_URL.USER

interface LoginResponse {
  accessToken: string
  name: string
}

export async function login(userId: string, password: string): Promise<LoginResponse> {
  const response = await createAxios().post(USER_URL.LOGIN, {
    userId,
    password
  })

  return {
    accessToken: response.data.accessToken,
    name: 'temp user'
  }
}

export async function logout(): Promise<any> {
  const response = await createAxios().post(USER_URL.LOGOUT)

  return response.data
}

export async function refresh(): Promise<LoginResponse> {
  const response = await createAxios().post(USER_URL.REFRESH)

  return {
    accessToken: response.data.accessToken,
    name: 'temp user'
  }
}

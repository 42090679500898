import { NOTIFICATION_KIND, useMainNotification } from '@cck/react'
import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import LoginViewComponent from 'component/login/LoginViewComponent'
import { useTokenChange } from 'hook/token/useTokenChange'

const LoginWrapperComponent: React.FC = () => {
  const { addNotification } = useMainNotification()
  const { isAuthenticated } = useTokenChange()
  const [searchParams] = useSearchParams()

  // URL에서 returnUrl 파라미터를 읽어옴
  const returnUrl = searchParams.get('returnUrl')

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    if (!returnUrl) {
      addNotification(
        NOTIFICATION_KIND.ERROR,
        '리다이렉트 실패',
        '로그인 후 기존 페이지가 없어 복귀하지 못했습니다.'
      )

      return
    }

    // 다른 도메인으로 이동해야 하므로 window.location.href 사용
    window.location.href = returnUrl

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, returnUrl])

  // Only render login component if not authenticated
  return !isAuthenticated ? <LoginViewComponent /> : null
}

export default LoginWrapperComponent

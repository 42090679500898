import { atom } from 'recoil'

export interface AccountSummary {
  email: string
  name: string
  lastLoginAt: string
}

export const emptyAccountInfo: AccountSummary = {
  email: '',
  name: '',
  lastLoginAt: ''
}

export const accountInfoAtom = atom<AccountSummary>({
  key: 'accountInfoAtom',
  default: emptyAccountInfo
})

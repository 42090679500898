import { HttpStatusCode } from '@cck/react'
import axios, { AxiosError } from 'axios'

import { mothershipTokenManager } from 'class/token-manager/MothershipTokenManager'

export const DEFAULT_TIMEOUT = 300000 // 5 minutes

export const DEFAULT_LONG_TIMEOUT = 480000 // 480 seconds = 8 minutes

export const JUMP_BASE_URL = window.location.origin

type AxiosCreateType = {
  timeout?: number
}

export const createAxios = (createType?: AxiosCreateType) => {
  const newAxios = axios.create({
    baseURL: JUMP_BASE_URL,
    timeout: DEFAULT_TIMEOUT,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
    // withCredentials: true
  })

  newAxios.interceptors.request.use(
    (config) => {
      const token = mothershipTokenManager.getAccessToken()
      if (token) {
        // request header 에 토큰 추가
        // 임시 사용. 추후 변경될 수 있음
        config.headers.Authorization = `Bearer ${token}`
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  // append interceptor callback function (pre-process)
  // to check response's data.code
  newAxios.interceptors.response.use(
    (response) => {
      const message = response.data?.message || ''
      const serviceURL = response.config.url || ''
      switch (response.status) {
        case HttpStatusCode.OK: {
          // const sessionTimer = SessionTimer.getInstance()
          // sessionTimer.resetTimer()

          // Success
          return response
        }
        case HttpStatusCode.BAD_REQUEST: {
          // Bad request
          throw new Error(`Bad request error occured in service call ${serviceURL}. [${message}]`)
        }
        case HttpStatusCode.UNAUTHORIZED: {
          // tokenTempStorage.clear()
          window.location.replace('/login')
          throw new Error(`Session has expired. [${message}]`)
        }
        case HttpStatusCode.FORBIDDEN: {
          // Forbidden
          throw new Error(`No permission. [${message}]`)
        }
        case HttpStatusCode.NOT_ACCEPTABLE: {
          // Not Acceptable
          throw new Error(`not acceptable. [${message}]`)
        }
        case HttpStatusCode.INTERNAL_SERVER_ERROR: {
          // Internal Server Error
          throw new Error(
            `Internal server error occured in service call ${serviceURL}. [${message}]`
          )
        }
        default: {
          throw new Error(`Some error occured in service call ${serviceURL}. [${message}]`)
        }
      }
    },
    (error: AxiosError) => {
      switch (error.response?.status) {
        case HttpStatusCode.UNAUTHORIZED: {
          if (window.location.pathname !== '/login') {
            window.location.replace('/login')
          }
          break
        }
        case HttpStatusCode.GONE: {
          break
        }
        case HttpStatusCode.BAD_REQUEST:
        case HttpStatusCode.FORBIDDEN:
        case HttpStatusCode.NOT_ACCEPTABLE:
        case HttpStatusCode.INTERNAL_SERVER_ERROR:
        default: {
          break
        }
      }

      return Promise.reject(error)
    }
  )

  return newAxios
}

export const normalAxios = createAxios()

export const longAxios = createAxios({ timeout: DEFAULT_LONG_TIMEOUT })

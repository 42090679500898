/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, ColGroupDef } from 'ag-grid-community'
import classNames from 'classnames'

import {
  CarbonAgGridCellEditor,
  CarbonAgGridCellRenderer,
  CarbonAgGridDateEditor,
  CarbonAgGridHeader,
  CarbonCheckboxColumnDefProps,
  CarbonCommonColumnDefProps,
  CarbonCommonGroupColumnDefProps,
  CarbonCustomColumnDefProps,
  CarbonCustomGroupColumnDefProps,
  CarbonLabelColumnDefProps,
  CarbonMoreLabelColumnDefProps
} from 'components/table/column/CarbonColumnDefTypes'
import CarbonBasicAgGridCellEditorComponent from 'components/table/control/CarbonBasicAgGridCellEditorComponent'
import CarbonCheckboxAgGridCellRendererComponent from 'components/table/control/CarbonCheckboxAgGridCellRendererComponent'
import CarbonCheckboxHeaderComponent from 'components/table/control/CarbonCheckboxHeaderComponent'
import CarbonCustomAgGridCellRendererComponent from 'components/table/control/CarbonCustomAgGridCellRendererComponent'
import CarbonCustomAgGridHeaderComponent from 'components/table/control/CarbonCustomAgGridHeaderComponent'
import CarbonLabelAgGridCellRendererComponent from 'components/table/control/CarbonLabelAgGridCellRendererComponent'
import CarbonMoreLabelCellComponent from 'components/table/control/CarbonMoreLabelCellComponent'
import EssentialAgGridHeaderComponent from 'components/table/control/EssentialAgGridHeaderComponent'
// ag-grid에서 사용하는 cellRenderer를 custom component로 wrapping
export const CarbonAgGridFrameworkComponents: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-unused-vars
  [key in
    | CarbonAgGridCellRenderer
    | CarbonAgGridCellEditor
    | CarbonAgGridHeader
    | CarbonAgGridDateEditor]: any
} = {
  [CarbonAgGridCellRenderer.Label]: CarbonLabelAgGridCellRendererComponent,
  [CarbonAgGridCellRenderer.MoreLabel]: CarbonMoreLabelCellComponent,
  [CarbonAgGridCellRenderer.Custom]: CarbonCustomAgGridCellRendererComponent,
  [CarbonAgGridCellRenderer.Checkbox]: CarbonCheckboxAgGridCellRendererComponent,
  [CarbonAgGridCellEditor.Basic]: CarbonBasicAgGridCellEditorComponent,
  [CarbonAgGridCellEditor.Modal]: undefined,
  [CarbonAgGridHeader.CheckBoxHeader]: CarbonCheckboxHeaderComponent,
  [CarbonAgGridHeader.EssentialHeader]: EssentialAgGridHeaderComponent,
  [CarbonAgGridHeader.CustomHeader]: CarbonCustomAgGridHeaderComponent,
  [CarbonAgGridHeader.CustomGroupHeader]: CarbonCustomAgGridHeaderComponent,
  autoComplete: undefined,
  onlyPaste: undefined,
  date: undefined,
  dateRange: undefined
}

// Column Header Class Name 생성
function getColumnHeaderClassNames(
  colDef: CarbonCommonColumnDefProps,
  inputClassNames: string[]
): string {
  // default column header class name
  const defaultColumnHeaderClassNames = classNames({
    'carbon-table-column-header-default': true,
    'carbon-table-column-header-align-center': colDef?.isHeaderCenterAlign ?? false,
    'ag-right-aligned-header': colDef?.isHeaderRightAlign ?? false,
    'carbon-table-left-border-default': colDef?.columnLeftBorder ?? false
  })
  const customHeaderClassNames = classNames({
    'carbon-table-custom-checkbox-header':
      colDef?.customHeaderType === CarbonAgGridHeader.CheckBoxHeader
  })
  // merge
  const columnHeaderClassNames = [
    // default
    defaultColumnHeaderClassNames,
    customHeaderClassNames,
    // input as className
    ...inputClassNames,
    // input as headerClass
    ...(colDef.headerClass?.toString().split(' ') ?? [])
  ]

  // to string
  return columnHeaderClassNames.join(' ')
}

function getCellClassNames(
  colDef: CarbonCommonColumnDefProps,
  inputClassNames: string[] = []
): string {
  // default column header class name
  const defaultCellClassNames = classNames({
    'carbon-table-cell-default': true,
    'carbon-table-cell-align-center': colDef?.isCellCenterAlign ?? false,
    'carbon-table-left-border-default': colDef?.columnLeftBorder ?? true
  })

  // merge
  const cellClassNames = [
    // default
    defaultCellClassNames,
    // input as className
    ...inputClassNames,
    // input as headerClass
    ...(colDef.cellClass?.toString().split(' ') ?? [])
  ]

  // to string
  return cellClassNames.join(' ')
}

function getCommonColumnDefs(
  commonColDefInfo: CarbonCommonColumnDefProps,
  type?: CarbonAgGridCellRenderer
): ColDef {
  const getDefaultSortable = () => {
    switch (type) {
      case 'label':
        return true
      default:
        return false
    }
  }

  return {
    suppressMenu: commonColDefInfo?.suppressMenu ?? true,
    // width가 지정된 경우에 suppressSizeToFit을 true로 설정해서 해당 column의 width를 고정시킴
    suppressSizeToFit: commonColDefInfo?.width !== undefined,
    sortable: commonColDefInfo?.sortable ?? getDefaultSortable(),
    headerComponent: commonColDefInfo.customHeaderType,
    headerComponentParams: {
      value: commonColDefInfo.customColumnValue,
      customHeaderProps: commonColDefInfo.customHeaderProps
    },
    suppressMovable: commonColDefInfo?.suppressMovable ?? true,
    autoHeight: commonColDefInfo?.autoHeight ?? false,
    wrapText: commonColDefInfo?.wrapText ?? commonColDefInfo?.autoHeight ?? false,
    flex: commonColDefInfo?.flex ?? 1
  }
}

// Label column definition
export function createCarbonLabelColumnDef(labelDefInfo: CarbonLabelColumnDefProps): ColDef {
  const columnDef = getCommonColumnDefs(labelDefInfo, CarbonAgGridCellRenderer.Label)

  columnDef.tooltipField = labelDefInfo.tooltipField ?? labelDefInfo.field
  return {
    ...labelDefInfo,
    cellRenderer: CarbonAgGridCellRenderer.Label,
    cellRendererParams: {
      convertValueCallbackFunc: labelDefInfo.convertValueCallbackFunc,
      placeholder: labelDefInfo.placeholder,
      postfixPlaceholder: labelDefInfo.postfixPlaceholder,
      isTextOverflowEllipsis: labelDefInfo.isTextOverflowEllipsis,
      appendPostfixCallbackFunc: labelDefInfo.appendPostfixCallbackFunc,
      labelType: labelDefInfo.labelType,
      labelTextAlign: labelDefInfo.labelTextAlign,
      labelTextVerticalAlign: labelDefInfo.labelTextVerticalAlign
    },
    cellEditor: CarbonAgGridCellEditor.Basic,
    cellEditorParams: {
      checkUniqueValueInColumn: labelDefInfo.checkUniqueValueInColumn,
      isValidateCheckCallbackFunc: labelDefInfo.isValidateCheckCallbackFunc,
      refinedInputValueCallbackFunc: labelDefInfo.refinedInputValueCallbackFunc,
      changeInputValueFilterCallbackFunc: labelDefInfo.changeInputValueFilterCallbackFunc,
      moveNextAfterEdit: labelDefInfo.moveNextAfterEdit
    },
    headerClass: getColumnHeaderClassNames(labelDefInfo, []),
    cellClass: [getCellClassNames(labelDefInfo), 'carbon-table-cell-label-default'],
    cellClassRules: {
      ...labelDefInfo.cellClassRules,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      'carbon-table-bottom-border-highlight': (params) => {
        // cellClassRules가 변경될 때마다 cellRenderer를 다시 호출
        // cellClass에 넣으면 ag-grid 최적화 로직에 따라 다시 안그려질 수 있으므로,
        // 동적으로 변해야하는 스타일에 대해서는 cellClassRules에 넣는 것이 좋음
        return labelDefInfo.cellBottomBorderHighlight ?? false
      }
    },
    ...columnDef
  }
}

export function createCustomColumnDef(customColumnDefInfo: CarbonCustomColumnDefProps): ColDef {
  return {
    ...customColumnDefInfo,
    cellRenderer: CarbonAgGridCellRenderer.Custom,
    cellRendererParams: {
      isSearchBlocked: customColumnDefInfo?.isSearchBlocked ?? true,
      autoHeight: customColumnDefInfo.autoHeight
    },
    headerClass: getColumnHeaderClassNames(customColumnDefInfo, []),
    cellClass: getCellClassNames(customColumnDefInfo, ['carbon-table-custom-cell-frame']),
    ...getCommonColumnDefs(customColumnDefInfo, CarbonAgGridCellRenderer.Custom)
  }
}

export function createCheckboxColumnDef(checkboxDefInfo: CarbonCheckboxColumnDefProps): ColDef {
  return {
    // headerName: header name
    // field: row data와 매핑되는 field name
    // cellStyle: cell에 적용하는 style
    // headerClass: header에 적용하는 style class name
    // cellClassRules: cell에 적용하는 style class name
    // suppressMenu: column header icon 제거하기
    ...checkboxDefInfo,
    // cellRenderer: renderer를 custom component로 교체(name으로 mapping)
    cellRenderer: CarbonAgGridCellRenderer.Checkbox,
    // cellRendererParams: renderer에 전달할 parameter
    cellRendererParams: {
      // value에 대해 검색이 불가능한 column으로 설정(custom parameter)
      isSearchBlocked: true,
      callbackCheckboxChange: checkboxDefInfo.callbackCheckboxChange,
      callbackIsContentVisible: checkboxDefInfo.callbackIsContentVisible,
      callbackIsContentDisable: checkboxDefInfo.callbackIsContentDisable,
      checkboxClickDisabled: checkboxDefInfo.checkboxClickDisabled
    },
    headerClass: getColumnHeaderClassNames(checkboxDefInfo, [
      'carbon-table-column-header-checkbox-default'
    ]),
    cellStyle: checkboxDefInfo.cellStyle,
    cellClass: getCellClassNames(checkboxDefInfo, ['carbon-table-cell-checkbox-default']),
    ...getCommonColumnDefs(checkboxDefInfo, CarbonAgGridCellRenderer.Checkbox)
  }
}

export function createCheckboxSelectorColumnDef(props: CarbonCommonColumnDefProps): ColDef {
  const selectorProps = {
    width: 60,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRendererParams: {
      isSearchBlocked: true
    },
    ...props
  }

  return {
    ...selectorProps,
    headerClass: getColumnHeaderClassNames(selectorProps, [
      'carbon-table-checkbox-selector-header'
    ]),
    cellClass: getCellClassNames(selectorProps, ['carbon-table-checkbox-selector']),
    ...getCommonColumnDefs(selectorProps)
  }
}

export function createMoreLabelColumnDef(
  moreLabelColumnDefInfo: CarbonMoreLabelColumnDefProps
): ColDef {
  return {
    ...moreLabelColumnDefInfo,
    cellRenderer: CarbonAgGridCellRenderer.MoreLabel,
    cellRendererParams: {
      autoHeight: moreLabelColumnDefInfo.autoHeight,
      lineCount: moreLabelColumnDefInfo.lineCount
    },
    headerClass: getColumnHeaderClassNames(moreLabelColumnDefInfo, []),
    cellClass: getCellClassNames(moreLabelColumnDefInfo, ['carbon-table-more-label-cell-frame']),
    ...getCommonColumnDefs(moreLabelColumnDefInfo, CarbonAgGridCellRenderer.MoreLabel)
  }
}

function getCustomColumnGroupHeaderClassNames(
  colDef: CarbonCommonGroupColumnDefProps,
  inputClassNames: string[]
): string {
  // default column header class name
  const defaultColumnHeaderClassNames = classNames({
    'carbon-table-custom-group-column-header-default': true,
    'carbon-table-custom-group-column-header-align-center': colDef?.isHeaderCenterAlign ?? false,
    'carbon-table-custom-group-column-header-align-right': colDef?.isHeaderRightAlign ?? false
  })

  // merge
  const columnHeaderClassNames = [
    // default
    defaultColumnHeaderClassNames,
    // input as className
    ...inputClassNames,
    // input as headerClass
    ...(colDef.headerClass?.toString().split(' ') ?? [])
  ]

  // to string
  return columnHeaderClassNames.join(' ')
}

export function createCustomGroupColDef(
  customGroupColumnDefInfo: CarbonCustomGroupColumnDefProps
): ColGroupDef {
  return {
    ...customGroupColumnDefInfo,
    headerClass: getCustomColumnGroupHeaderClassNames(customGroupColumnDefInfo, []),
    headerGroupComponent: CarbonAgGridHeader.CustomGroupHeader,
    headerGroupComponentParams: {
      value: customGroupColumnDefInfo.customColumnValue
    }
  }
}

import { Column, GridApi, RowNode } from 'ag-grid-community'
import React from 'react'

import CarbonCheckboxComponent from 'components/checkbox/CarbonCheckboxComponent'
import { CarbonCommonAgGridCellRendererProps } from 'components/table/control/CarbonAgGridTypes'

interface CheckboxAgGridCellRendererComponentProps extends CarbonCommonAgGridCellRendererProps {
  callbackCheckboxChange?: (
    checked: boolean,
    gridApi: GridApi,
    targetColumnNode: RowNode,
    column: Column
  ) => void
  checkboxClickDisabled?: boolean
}

const CarbonCheckboxAgGridCellRendererComponent: React.FC<
  CheckboxAgGridCellRendererComponentProps
> = ({
  node,
  api,
  column,
  checkboxClickDisabled,
  callbackCheckboxChange,
  callbackIsContentDisable,
  callbackIsContentVisible
}) => {
  const handleCheckboxStateChange = (checked: boolean) => {
    // Check 값 반영
    node.setDataValue(column.getColId(), checked)
    // Checkbox Component에서 발생한 event를 ag-grid prop들과 조합하여 callback으로 전달
    callbackCheckboxChange && callbackCheckboxChange(checked, api, node, column)
  }

  const visible = callbackIsContentVisible ? callbackIsContentVisible(node, column) : true
  const disable = callbackIsContentDisable ? callbackIsContentDisable(node, column) : false
  const checked = Boolean(node?.data[column.getColId()])
  return (
    <div
      className={`carbon-table-renderer-common carbon-table-checkbox carbon-table-checkbox-${
        checkboxClickDisabled ? 'click-disable' : 'click-enable'
      }`}
    >
      {visible && (
        <CarbonCheckboxComponent
          checkboxProps={{ id: `carbon-check-${node.id}`, labelText: '', disabled: disable }}
          checked={checked}
          onChangeCallbackFun={(newChecked) => handleCheckboxStateChange(newChecked)}
        />
      )}
    </div>
  )
}

CarbonCheckboxAgGridCellRendererComponent.displayName = 'CarbonCheckboxAgGridCellRendererComponent'

export default CarbonCheckboxAgGridCellRendererComponent

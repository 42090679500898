/* eslint-disable @typescript-eslint/no-explicit-any */
import BrowserStorageHelper from 'class/browser-storage/BrowserStorageHelper'

export const BROWSER_STORAGE_PRODUCT_KEY = {
  ACCIO: 'ACCIO',
  PRISM: 'PRISM',
  IMPACT: 'IMPACT',
  JUMP: 'JUMP',
  MOTHERSHIP: 'MOTHERSHIP'
} as const

export type BROWSER_STORAGE_PRODUCT_KEY =
  typeof BROWSER_STORAGE_PRODUCT_KEY[keyof typeof BROWSER_STORAGE_PRODUCT_KEY]

export interface BrowserStorageMapper<T> {
  convertStringToFormat(json: any): T
  convertFormatToString(target: T): any
}

abstract class BrowserStorage<T> {
  protected key: string
  protected mapper: BrowserStorageMapper<T>

  constructor(
    productKey: BROWSER_STORAGE_PRODUCT_KEY,
    key: string,
    mapper: BrowserStorageMapper<T>
  ) {
    this.key = `${productKey}_${key}`
    this.mapper = mapper
  }

  get(): T | undefined {
    const value = BrowserStorageHelper.get(this.key)
    if (!value) {
      return undefined
    }

    return this.mapper.convertStringToFormat(value)
  }

  set(target: T, temporary = false): boolean {
    const convertedTarget = this.mapper.convertFormatToString(target)
    if (!convertedTarget) {
      return false
    }

    return BrowserStorageHelper.set(this.key, convertedTarget, temporary)
  }

  clear() {
    return BrowserStorageHelper.clear(this.key)
  }
}

export default BrowserStorage

import { DatePicker } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import React from 'react'

import './_date.scss'
import { RangePickerProps } from 'antd/es/date-picker'

const { RangePicker } = DatePicker

interface YearProps {
  setYear: (year: number) => void
  placeholder?: string
}

interface CarbonYearRangeComponentProps {
  title?: string
  startYearProps: YearProps
  endYearProps: YearProps
  minYear?: number
  maxYear?: number
}

const CarbonYearRangeComponent: React.FC<CarbonYearRangeComponentProps> = ({
  title,
  startYearProps: { setYear: setStartYear, placeholder: startYearPlaceholder = '' },
  endYearProps: { setYear: setEndYear, placeholder: endYearPlaceholder = '' },
  minYear,
  maxYear
}) => {
  const disabledDateFunc: RangePickerProps['disabledDate'] = (current: Dayjs) => {
    const year = current.toDate().getFullYear()

    if (minYear !== undefined && year < minYear) {
      return true
    }

    if (maxYear !== undefined && year > maxYear) {
      return true
    }

    return false
  }

  return (
    <div className="carbon-style-year-range-wrapper">
      {title && <div className="carbon-style-year-range-title-wrapper">{title}</div>}
      <RangePicker
        disabledDate={disabledDateFunc}
        picker="year"
        placeholder={[startYearPlaceholder, endYearPlaceholder]}
        onChange={(values) => {
          if (values === null) {
            return
          }

          const startYearValue = values[0]?.toDate().getFullYear()
          if (startYearValue !== undefined) {
            setStartYear(startYearValue)
          }

          const endYearValue = values[1]?.toDate().getFullYear()
          if (endYearValue !== undefined) {
            setEndYear(endYearValue)
          }
        }}
      />
    </div>
  )
}

export default CarbonYearRangeComponent

import { MainNotificationProvider } from '@cck/react'
import React from 'react'
import 'mothership.scss'
import { BrowserRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'

import MothershipMainViewRouteComponent from 'component/route/MothershipMainViewRouteComponent'

const MothershipRootComponent: React.FC = () => {
  return (
    <RecoilRoot>
      <MainNotificationProvider>
        <div className="mothership-root-frame">
          <BrowserRouter>
            <MothershipMainViewRouteComponent />
          </BrowserRouter>
        </div>
      </MainNotificationProvider>
    </RecoilRoot>
  )
}

export default MothershipRootComponent

/* eslint-disable no-template-curly-in-string */
/* eslint-disable max-len */
/**
 * Please note:
 *
 * Translations are provided as an illustration only and are
 * not guaranteed to be accurate or error free.
 *
 * They are designed to show developers where to store their
 * chosen phrase or spelling variant in the target language.
 */

export const AG_GRID_LOCALE_KR = {
  // Set Filter
  selectAll: '(전체 선택)',
  selectAllSearchResults: '(검색 결과 모두 선택)',
  addCurrentSelectionToFilter: '현재 선택을 필터에 추가',
  searchOoo: '검색...',
  blanks: '(빈칸)',
  noMatches: '일치하는 항목이 없습니다',

  // Number Filter & Text Filter
  filterOoo: '필터...',
  equals: '같음',
  notEqual: '같지 않음',
  blank: '빈칸',
  notBlank: '비어 있지 않음',
  empty: '선택하세요',

  // Number Filter
  lessThan: '보다 작음',
  greaterThan: '보다 큼',
  lessThanOrEqual: '이하',
  greaterThanOrEqual: '이상',
  inRange: '사이',
  inRangeStart: '부터',
  inRangeEnd: '까지',

  // Text Filter
  contains: '포함',
  notContains: '포함하지 않음',
  startsWith: '시작',
  endsWith: '끝',

  // Date Filter
  dateFormatOoo: 'yyyy-mm-dd',
  before: '이전',
  after: '이후',

  // Filter Conditions
  andCondition: 'AND',
  orCondition: 'OR',

  // Filter Buttons
  applyFilter: '적용',
  resetFilter: '재설정',
  clearFilter: '지우기',
  cancelFilter: '취소',

  // Filter Titles
  textFilter: '텍스트 필터',
  numberFilter: '숫자 필터',
  dateFilter: '날짜 필터',
  setFilter: '세트 필터',

  // Group Column Filter
  groupFilterSelect: '필드를 선택하세요:',

  // Advanced Filter
  advancedFilterContains: '포함',
  advancedFilterNotContains: '포함하지 않음',
  advancedFilterTextEquals: '같음',
  advancedFilterTextNotEqual: '같지 않음',
  advancedFilterStartsWith: '시작이',
  advancedFilterEndsWith: '끝이',
  advancedFilterBlank: '비어 있음',
  advancedFilterNotBlank: '비어 있지 않음',
  advancedFilterEquals: '=',
  advancedFilterNotEqual: '!=',
  advancedFilterGreaterThan: '>',
  advancedFilterGreaterThanOrEqual: '>=',
  advancedFilterLessThan: '<',
  advancedFilterLessThanOrEqual: '<=',
  advancedFilterTrue: '참임',
  advancedFilterFalse: '거짓임',
  advancedFilterAnd: 'AND',
  advancedFilterOr: 'OR',
  advancedFilterApply: '적용',
  advancedFilterBuilder: '생성기',
  advancedFilterValidationMissingColumn: '열이 누락되었습니다',
  advancedFilterValidationMissingOption: '옵션이 누락되었습니다',
  advancedFilterValidationMissingValue: '값이 누락되었습니다',
  advancedFilterValidationInvalidColumn: '열을 찾을 수 없습니다',
  advancedFilterValidationInvalidOption: '옵션을 찾을 수 없습니다',
  advancedFilterValidationMissingQuote: '값에 끝나는 인용부호가 없습니다',
  advancedFilterValidationNotANumber: '값이 숫자가 아닙니다',
  advancedFilterValidationInvalidDate: '값이 유효한 날짜가 아닙니다',
  advancedFilterValidationMissingCondition: '조건이 누락되었습니다',
  advancedFilterValidationJoinOperatorMismatch: '조건 내의 연결 연산자는 동일해야 합니다',
  advancedFilterValidationInvalidJoinOperator: '연결 연산자를 찾을 수 없습니다',
  advancedFilterValidationMissingEndBracket: '끝 괄호가 누락되었습니다',
  advancedFilterValidationExtraEndBracket: '종결 괄호가 너무 많습니다',
  advancedFilterValidationMessage: '식에 오류가 있습니다. ${variable} - ${variable}.',
  advancedFilterValidationMessageAtEnd: '식에 오류가 있습니다. ${variable} 식의 끝에 있습니다.',
  advancedFilterBuilderTitle: '고급 필터',
  advancedFilterBuilderApply: '적용',
  advancedFilterBuilderCancel: '취소',
  advancedFilterBuilderAddButtonTooltip: '필터 또는 그룹 추가',
  advancedFilterBuilderRemoveButtonTooltip: '제거',
  advancedFilterBuilderMoveUpButtonTooltip: '위로 이동',
  advancedFilterBuilderMoveDownButtonTooltip: '아래로 이동',
  advancedFilterBuilderAddJoin: '그룹 추가',
  advancedFilterBuilderAddCondition: '필터 추가',
  advancedFilterBuilderSelectColumn: '열 선택',
  advancedFilterBuilderSelectOption: '옵션 선택',
  advancedFilterBuilderEnterValue: '값을 입력하세요...',
  advancedFilterBuilderValidationAlreadyApplied: '현재 필터가 이미 적용되었습니다.',
  advancedFilterBuilderValidationIncomplete: '모든 조건이 완료되지 않았습니다.',
  advancedFilterBuilderValidationSelectColumn: '열을 선택해야 합니다.',
  advancedFilterBuilderValidationSelectOption: '옵션을 선택해야 합니다.',
  advancedFilterBuilderValidationEnterValue: '값을 입력해야 합니다.',

  // Side Bar
  columns: '열',
  filters: '필터',

  // columns tool panel
  pivotMode: '피벗 모드',
  groups: '행 그룹',
  rowGroupColumnsEmptyMessage: '여기로 드래그하여 행 그룹 설정',
  values: '값',
  valueColumnsEmptyMessage: '여기로 드래그하여 집계',
  pivots: '열 레이블',
  pivotColumnsEmptyMessage: '여기로 드래그하여 열 레이블 설정',

  // Header of the Default Group Column
  group: '그룹',

  // Row Drag
  rowDragRow: '행',
  rowDragRows: '행들',

  // Other
  loadingOoo: '로딩 중...',
  loadingError: '오류',
  noRowsToShow: '표시할 행이 없습니다',
  enabled: '사용 중',

  // Menu
  pinColumn: '열 고정',
  pinLeft: '왼쪽에 고정',
  pinRight: '오른쪽에 고정',
  noPin: '고정 없음',
  valueAggregation: '값 집계',
  noAggregation: '없음',
  autosizeThisColumn: '이 열 자동 크기 조정',
  autosizeAllColumns: '모든 열 자동 크기 조정',
  groupBy: '그룹화 기준',
  ungroupBy: '그룹 해제 기준',
  ungroupAll: '모든 그룹 해제',
  addToValues: '${variable} 값을 추가',
  removeFromValues: '${variable} 값을 제거',
  addToLabels: '${variable} 레이블에 추가',
  removeFromLabels: '${variable} 레이블에서 제거',
  resetColumns: '열 재설정',
  expandAll: '모든 행 그룹 확장',
  collapseAll: '모든 행 그룹 닫기',
  copy: '복사',
  ctrlC: 'Ctrl+C',
  ctrlX: 'Ctrl+X',
  copyWithHeaders: '머리글과 함께 복사',
  copyWithGroupHeaders: '그룹 머리글과 함께 복사',
  cut: '잘라내기',
  paste: '붙여넣기',
  ctrlV: 'Ctrl+V',
  export: '내보내기',
  csvExport: 'CSV 내보내기',
  excelExport: 'Excel 내보내기',
  columnFilter: '열 필터',
  columnChooser: '열 선택',
  sortAscending: '오름차순 정렬',
  sortDescending: '내림차순 정렬',
  sortUnSort: '정렬 해제',

  // Enterprise Menu Aggregation and Status Bar
  sum: '합계',
  first: '첫 번째',
  last: '마지막',
  min: '최소',
  max: '최대',
  none: '없음',
  count: '개수',
  avg: '평균',
  filteredRows: '필터된 행',
  selectedRows: '선택된 행',
  totalRows: '총 행 수',
  totalAndFilteredRows: '행',
  more: '더 보기',
  to: '에서',
  of: '의',
  page: '페이지',
  pageLastRowUnknown: '?',
  nextPage: '다음 페이지',
  lastPage: '마지막 페이지',
  firstPage: '첫 페이지',
  previousPage: '이전 페이지',
  pageSizeSelectorLabel: '페이지 크기:',
  footerTotal: '총계',

  // Pivoting
  pivotColumnGroupTotals: '총합계',

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: '피벗 차트 & 피벗 모드',
  pivotChart: '피벗 차트',
  chartRange: '차트 범위',
  columnChart: '컬럼',
  groupedColumn: '그룹화',
  stackedColumn: '스택',
  normalizedColumn: '100% 스택',
  barChart: '막대',
  groupedBar: '그룹화',
  stackedBar: '스택',
  normalizedBar: '100% 스택',
  pieChart: '파이',
  pie: '파이',
  donut: '도넛',
  line: '라인',
  xyChart: 'X Y (산점도)',
  scatter: '산점도',
  bubble: '버블',
  areaChart: '영역',
  area: '영역',
  stackedArea: '스택 영역',
  normalizedArea: '100% 스택 영역',
  histogramChart: '히스토그램',
  polarChart: '폴라',
  radarLine: '레이더 라인',
  radarArea: '레이더 영역',
  nightingale: '나이팅게일',
  radialColumn: '방사형 컬럼',
  radialBar: '방사형 막대',
  statisticalChart: '통계',
  boxPlot: '상자 그림',
  rangeBar: '범위 막대',
  rangeArea: '범위 영역',
  hierarchicalChart: '계층적',
  treemap: '트리맵',
  sunburst: '선버스트',
  specializedChart: '특수',
  waterfall: '폭포',
  heatmap: '히트맵',
  combinationChart: '복합',
  columnLineCombo: '컬럼 & 라인',
  AreaColumnCombo: '영역 & 컬럼',

  // Charts
  pivotChartTitle: '피벗 차트',
  rangeChartTitle: '범위 차트',
  settings: '차트',
  data: '설정',
  format: '맞춤 설정',
  categories: '카테고리',
  defaultCategory: '(없음)',
  series: '시리즈',
  switchCategorySeries: '카테고리 / 시리즈 전환',
  categoryValues: '카테고리 값',
  seriesLabels: '시리즈 라벨',
  aggregate: '합산',
  xyValues: 'X Y 값들',
  paired: '쌍 모드',
  axis: '축',
  xAxis: '수평 축',
  yAxis: '수직 축',
  polarAxis: '폴라 축',
  radiusAxis: '반경 축',
  navigator: '네비게이터',
  zoom: '줌',
  animation: '애니메이션',
  crosshair: '크로스헤어',
  color: '색상',
  thickness: '두께',
  preferredLength: '선호 길이',
  xType: 'X 유형',
  axisType: '축 유형',
  automatic: '자동',
  category: '카테고리',
  number: '숫자',
  time: '시간',
  timeFormat: '시간 형식',
  autoRotate: '자동 회전',
  labelRotation: '회전',
  circle: '원',
  polygon: '다각형',
  square: '사각형',
  cross: '십자',
  diamond: '다이아몬드',
  plus: '플러스',
  triangle: '삼각형',
  heart: '하트',
  orientation: '방향',
  fixed: '고정',
  parallel: '병렬',
  perpendicular: '수직',
  radiusAxisPosition: '위치',
  ticks: '틱',
  gridLines: '격자 라인',
  width: '너비',
  height: '높이',
  length: '길이',
  padding: '패딩',
  spacing: '간격',
  chartStyle: '차트 스타일',
  title: '제목',
  chartTitles: '제목들',
  chartTitle: '차트 제목',
  chartSubtitle: '부제목',
  horizontalAxisTitle: '수평 축 제목',
  verticalAxisTitle: '수직 축 제목',
  polarAxisTitle: '폴라 축 제목',
  titlePlaceholder: '차트 제목',
  background: '배경',
  font: '글꼴',
  weight: '굵기',
  top: '위',
  right: '오른쪽',
  bottom: '아래',
  left: '왼쪽',
  labels: '라벨',
  calloutLabels: '콜아웃 라벨',
  sectorLabels: '섹터 라벨',
  positionRatio: '위치 비율',
  size: '크기',
  shape: '형태',
  minSize: '최소 크기',
  maxSize: '최대 크기',
  legend: '범례',
  position: '위치',
  markerSize: '마커 크기',
  markerStroke: '마커 스트로크',
  markerPadding: '마커 패딩',
  itemSpacing: '아이템 간격',
  itemPaddingX: '아이템 패딩 X',
  itemPaddingY: '아이템 패딩 Y',
  layoutHorizontalSpacing: '수평 간격',
  layoutVerticalSpacing: '수직 간격',
  strokeWidth: '스트로크 두께',
  offset: '오프셋',
  offsets: '오프셋들',
  tooltips: '툴팁',
  callout: '콜아웃',
  markers: '마커',
  shadow: '그림자',
  blur: '블러',
  xOffset: 'X 오프셋',
  yOffset: 'Y 오프셋',
  lineWidth: '라인 너비',
  lineDash: '라인 대시',
  lineDashOffset: '대시 오프셋',
  scrollingZoom: '스크롤',
  scrollingStep: '스크롤 스텝',
  selectingZoom: '선택',
  durationMillis: '지속 시간 (ms)',
  crosshairLabel: '라벨',
  crosshairSnap: '노드에 스냅',
  normal: '보통',
  bold: '굵게',
  italic: '기울임꼴',
  boldItalic: '굵게 기울임꼴',
  predefined: '사전 정의된',
  fillOpacity: '채우기 불투명도',
  strokeColor: '라인 색상',
  strokeOpacity: '라인 불투명도',
  miniChart: '미니 차트',
  histogramBinCount: '빈 수',
  connectorLine: '연결 라인',
  seriesItems: '시리즈 항목',
  seriesItemType: '항목 유형',
  seriesItemPositive: '양수',
  seriesItemNegative: '음수',
  seriesItemLabels: '항목 라벨',
  columnGroup: '열',
  barGroup: '막대',
  pieGroup: '파이',
  lineGroup: '라인',
  scatterGroup: 'X Y (산점도)',
  areaGroup: '면적',
  polarGroup: '폴라',
  statisticalGroup: '통계',
  hierarchicalGroup: '계층',
  specializedGroup: '전문',
  combinationGroup: '결합',
  groupedColumnTooltip: '그룹화',
  stackedColumnTooltip: '스택',
  normalizedColumnTooltip: '100% 스택',
  groupedBarTooltip: '그룹화',
  stackedBarTooltip: '스택',
  normalizedBarTooltip: '100% 스택',
  pieTooltip: '파이',
  donutTooltip: '도넛',
  lineTooltip: '라인',
  groupedAreaTooltip: '면적',
  stackedAreaTooltip: '스택',
  normalizedAreaTooltip: '100% 스택',
  scatterTooltip: '산점도',
  bubbleTooltip: '버블',
  histogramTooltip: '히스토그램',
  radialColumnTooltip: '반경 열',
  radialBarTooltip: '반경 막대',
  radarLineTooltip: '레이더 라인',
  radarAreaTooltip: '레이더 면적',
  nightingaleTooltip: '나이팅게일',
  rangeBarTooltip: '범위 막대',
  rangeAreaTooltip: '범위 면적',
  boxPlotTooltip: '박스 플롯',
  treemapTooltip: '트리맵',
  sunburstTooltip: '선버스트',
  waterfallTooltip: '폭포',
  heatmapTooltip: '히트맵',
  columnLineComboTooltip: '열 & 라인',
  areaColumnComboTooltip: '면적 & 열',
  customComboTooltip: '사용자 정의 결합',
  innerRadius: '내부 반경',
  startAngle: '시작 각도',
  endAngle: '끝 각도',
  reverseDirection: '반대 방향',
  groupPadding: '그룹 패딩',
  seriesPadding: '시리즈 패딩',
  tile: '타일',
  whisker: '수염',
  cap: '모자',
  capLengthRatio: '길이 비율',
  labelPlacement: '라벨 배치',
  inside: '내부',
  outside: '외부',
  noDataToChart: '차트화 할 수 있는 데이터가 없습니다.',
  pivotChartRequiresPivotMode: '피벗 차트는 피벗 모드가 활성화 되어야 합니다.',
  chartSettingsToolbarTooltip: '메뉴',
  chartLinkToolbarTooltip: '그리드에 링크됨',
  chartUnlinkToolbarTooltip: '그리드에서 링크 해제됨',
  chartDownloadToolbarTooltip: '차트 다운로드',
  chartMenuToolbarTooltip: '메뉴',
  chartEdit: '차트 편집',
  chartAdvancedSettings: '고급 설정',
  chartLink: '그리드에 링크',
  chartUnlink: '그리드에서 링크 해제',
  chartDownload: '차트 다운로드',
  histogramFrequency: '빈도',
  seriesChartType: '시리즈 차트 유형',
  seriesType: '시리즈 유형',
  secondaryAxis: '보조 축',
  seriesAdd: '시리즈 추가',
  categoryAdd: '카테고리 추가',
  bar: '막대',
  column: '열',
  histogram: '히스토그램',
  advancedSettings: '고급 설정',
  direction: '방향',
  horizontal: '수평',
  vertical: '수직',
  seriesGroupType: '그룹 유형',
  groupedSeriesGroupType: '그룹화',
  stackedSeriesGroupType: '스택',
  normalizedSeriesGroupType: '100% 스택',
  legendEnabled: '활성화됨',
  invalidColor: '색상 값이 유효하지 않습니다',
  groupedColumnFull: '그룹화된 열',
  stackedColumnFull: '스택된 열',
  normalizedColumnFull: '100% 스택된 열',
  groupedBarFull: '그룹화된 막대',
  stackedBarFull: '스택된 막대',
  normalizedBarFull: '100% 스택된 막대',
  stackedAreaFull: '스택된 면적',
  normalizedAreaFull: '100% 스택된 면적',
  customCombo: '사용자 정의 결합',

  // ARIA
  ariaAdvancedFilterBuilderItem: '${variable}. 레벨 ${variable}. 편집하려면 ENTER를 누르세요',
  ariaAdvancedFilterBuilderItemValidation:
    '${variable}. 레벨 ${variable}. ${variable} 편집하려면 ENTER를 누르세요',
  ariaAdvancedFilterBuilderList: '고급 필터 빌더 목록',
  ariaAdvancedFilterBuilderFilterItem: '필터 조건',
  ariaAdvancedFilterBuilderGroupItem: '필터 그룹',
  ariaAdvancedFilterBuilderColumn: '열',
  ariaAdvancedFilterBuilderOption: '옵션',
  ariaAdvancedFilterBuilderValueP: '값',
  ariaAdvancedFilterBuilderJoinOperator: '조합 연산자',
  ariaAdvancedFilterInput: '고급 필터 입력',
  ariaChecked: '선택됨',
  ariaColumn: '열',
  ariaColumnGroup: '열 그룹',
  ariaColumnFiltered: '열 필터링됨',
  ariaColumnSelectAll: '모든 열 선택 전환',
  ariaDateFilterInput: '날짜 필터 입력',
  ariaDefaultListName: '목록',
  ariaFilterColumnsInput: '열 필터 입력',
  ariaFilterFromValue: '값에서 필터',
  ariaFilterInput: '필터 입력',
  ariaFilterList: '필터 목록',
  ariaFilterToValue: '값까지 필터',
  ariaFilterValue: '필터 값',
  ariaFilterMenuOpen: '필터 메뉴 열기',
  ariaFilteringOperator: '필터링 연산자',
  ariaHidden: '숨김',
  ariaIndeterminate: '불확정',
  ariaInputEditor: '입력 에디터',
  ariaMenuColumn: '열 메뉴를 열려면 ALT 아래를 누르세요',
  ariaFilterColumn: '필터를 열려면 CTRL ENTER를 누르세요',
  ariaRowDeselect: '이 행을 선택 해제하려면 SPACE를 누르세요',
  ariaRowSelectAll: '모든 행 선택 전환을 위해 SPACE를 누르세요',
  ariaRowToggleSelection: '행 선택을 전환하려면 SPACE를 누르세요',
  ariaRowSelect: '이 행을 선택하려면 SPACE를 누르세요',
  ariaRowSelectionDisabled: '이 행의 행 선택이 비활성화되었습니다',
  ariaSearch: '검색',
  ariaSortableColumn: '정렬하려면 ENTER를 누르세요',
  ariaToggleVisibility: '가시성을 전환하려면 SPACE를 누르세요',
  ariaToggleCellValue: '셀 값을 전환하려면 SPACE를 누르세요',
  ariaUnchecked: '선택 해제됨',
  ariaVisible: '보이는',
  ariaSearchFilterValues: '필터 값 검색',
  ariaPageSizeSelectorLabel: '페이지 크기',
  ariaChartMenuClose: '차트 편집 메뉴 닫기',
  ariaChartSelected: '선택됨',
  ariaSkeletonCellLoadingFailed: '행 로드 실패',
  ariaSkeletonCellLoading: '행 데이터 로드 중',

  // ARIA Labels for Drop Zones
  ariaRowGroupDropZonePanelLabel: '행 그룹',
  ariaValuesDropZonePanelLabel: '값',
  ariaPivotDropZonePanelLabel: '열 레이블',
  ariaDropZoneColumnComponentDescription: '제거하려면 DELETE를 누르세요',
  ariaDropZoneColumnValueItemDescription: '집계 유형을 변경하려면 ENTER를 누르세요',
  ariaDropZoneColumnGroupItemDescription: '정렬하려면 ENTER를 누르세요',

  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: ' 의 ',
  ariaDropZoneColumnComponentSortAscending: '오름차순',
  ariaDropZoneColumnComponentSortDescending: '내림차순',
  ariaLabelDialog: '대화상자',
  ariaLabelColumnMenu: '컬럼 메뉴',
  ariaLabelColumnFilter: '컬럼 필터',
  ariaLabelCellEditor: '셀 편집기',
  ariaLabelSelectField: '필드 선택',

  // aria labels for rich select
  ariaLabelRichSelectField: '리치 선택 필드',
  ariaLabelRichSelectToggleSelection: '선택을 토글하려면 SPACE를 누르세요',
  ariaLabelRichSelectDeselectAllItems: '모든 항목 선택 해제를 위해 DELETE를 누르세요',
  ariaLabelRichSelectDeleteSelection: '항목 선택 해제를 위해 DELETE를 누르세요',
  ariaLabelTooltip: '툴팁',
  ariaLabelContextMenu: '컨텍스트 메뉴',
  ariaLabelSubMenu: '하위 메뉴',
  ariaLabelAggregationFunction: '집계 함수',
  ariaLabelAdvancedFilterAutocomplete: '고급 필터 자동 완성',
  ariaLabelAdvancedFilterBuilderAddField: '고급 필터 빌더 필드 추가',
  ariaLabelAdvancedFilterBuilderColumnSelectField: '고급 필터 빌더 칼럼 선택 필드',
  ariaLabelAdvancedFilterBuilderOptionSelectField: '고급 필터 빌더 옵션 선택 필드',
  ariaLabelAdvancedFilterBuilderJoinSelectField: '고급 필터 빌더 조인 연산자 선택 필드',

  // ARIA Labels for the Side Bar
  ariaColumnPanelList: '열 목록',
  ariaFilterPanelList: '필터 목록',

  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ',',
  decimalSeparator: '.',

  // Data types
  true: 'True',
  false: 'False',
  invalidDate: '유효하지 않은 날짜',
  invalidNumber: '유효하지 않은 숫자',
  january: '1월',
  february: '2월',
  march: '3월',
  april: '4월',
  may: '5월',
  june: '6월',
  july: '7월',
  august: '8월',
  september: '9월',
  october: '10월',
  november: '11월',
  december: '12월',

  // Time formats
  timeFormatSlashesDDMMYYYY: 'DD/MM/YYYY',
  timeFormatSlashesMMDDYYYY: 'MM/DD/YYYY',
  timeFormatSlashesDDMMYY: 'DD/MM/YY',
  timeFormatSlashesMMDDYY: 'MM/DD/YY',
  timeFormatDotsDDMYY: 'DD.MM.YY',
  timeFormatDotsMDDYY: 'M.DD.YY',
  timeFormatDashesYYYYMMDD: 'YYYY-MM-DD',
  timeFormatSpacesDDMMMMYYYY: 'DD MMMM YYYY',
  timeFormatHHMMSS: 'HH:MM:SS',
  timeFormatHHMMSSAmPm: 'HH:MM:SS 오전/오후'
}

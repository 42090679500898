import { isEmpty } from 'utils/common_utils'

export const isZeroAmount = (valueStr: string) => {
  const zeroAmountStrList = ['-', '', '…']
  if (zeroAmountStrList.includes(valueStr)) {
    return true
  }
  // 문자열을 숫자로 변환
  const num = parseFloat(valueStr)

  // 숫자가 유효하고 0인 경우 true 반환
  return !isNaN(num) && num === 0
}

const convertMinusToBracket = (valueStr: string) => {
  if (valueStr.startsWith('-')) {
    valueStr = `(${valueStr.slice(1)})`
  }
  return valueStr
}

const convertBracketToMinus = (valueStr: string) => {
  if (valueStr.startsWith('(') && valueStr.endsWith(')')) {
    valueStr = `-${valueStr.slice(1, -1)}`
  }
  return valueStr
}

const removeCommaAndZeroWidthSpace = (valueStr: string) => {
  return valueStr.replaceAll(',', '').replaceAll(/\u200B/g, '')
}

const convertNumStrToNumber = (valueStr: string): number | undefined => {
  if (isZeroAmount(valueStr)) {
    return 0
  }

  const numValue = Number(valueStr)
  if (isNaN(numValue)) {
    return undefined
  }

  return numValue
}

export const convertAmountToThousandSeparatedAmountStr = (
  value: string | number | undefined | null,
  withHandleMinus = true,
  withHandleZero = true
): string => {
  if (value === undefined || value === null) {
    return ''
  }

  // Remove comma and zero width space
  const amountValueStr = removeCommaAndZeroWidthSpace(
    typeof value === 'number' ? value.toString() : value
  )

  // Convert string to number(with minus)
  const convertedNumValue = convertNumStrToNumber(convertBracketToMinus(amountValueStr))
  if (convertedNumValue === undefined) {
    return `NaN(${amountValueStr})`
  }

  // format number to thousand separated string
  const formattedAmountResultStr = new Intl.NumberFormat('en-US').format(convertedNumValue)

  // If the amount is zero, return '-'
  if (isZeroAmount(formattedAmountResultStr)) {
    if (withHandleZero) {
      return '-'
    }
    return '0'
  }

  // If the amount is not minus, return formatted amount
  if (!withHandleMinus) {
    return formattedAmountResultStr
  }

  // Convert minus to bracket
  return convertMinusToBracket(formattedAmountResultStr)
}

export const isMinusAmount = (amountValue: string): boolean => {
  if (amountValue === '' || amountValue === '-') {
    return false
  }
  return (amountValue.startsWith('(') && amountValue.endsWith(')')) || amountValue.startsWith('-')
}

export const convertAmountStrToNumber = (amountInput: string): number => {
  const amountStr = amountInput.trim()

  // Remove comma and zero width space
  const cleanedStr = removeCommaAndZeroWidthSpace(amountStr)

  // Convert bracket to minus if necessary
  const minusHandledStr = convertBracketToMinus(cleanedStr)

  // Convert string to number
  const convertedNum = convertNumStrToNumber(minusHandledStr)

  if (convertedNum === undefined) {
    // 지수 표현식인 경우, 숫자로 변환하여 반환
    const scientificNum = parseFloat(minusHandledStr)
    if (!isNaN(scientificNum)) {
      return scientificNum
    }
    return NaN
  }

  return convertedNum
}

export const isAmountStr = (amountStr?: string): boolean => {
  if (isEmpty(amountStr)) {
    return true // 빈 문자열이나 undefined는 금액으로 간주합니다.
  }
  // 쉼표, zero space 제거
  const cleanedValue = amountStr.replaceAll(/,/g, '').replaceAll(/\u200B/g, '')

  return Number.isFinite(Number(cleanedValue))
}

import * as CarbonColors from '@carbon/colors'
import * as Icons from '@carbon/icons-react'
import { Tooltip, type TooltipProps } from 'antd'
import classNames from 'classnames'
import React from 'react'

import './_icon.scss'
import { SVGIconType } from 'components/icon/IconBaseType'

export interface ImageResourceData {
  imageString: string | SVGIconType
  isSvg?: boolean
  width?: number
  height?: number
  fillColor?: string
  fillColorProperty?: number
  strokeColor?: string
  strokeColorProperty?: number
}

type CarbonIconWrapperComponentProps = {
  iconData: ImageResourceData
  onClick?: () => void
  tooltipProp?: TooltipProps
}

const CarbonIconWrapperComponent: React.FC<CarbonIconWrapperComponentProps> = ({
  iconData,
  onClick,
  tooltipProp
}) => {
  const {
    imageString,
    isSvg = false,
    width = null,
    height = null,
    fillColor = null,
    fillColorProperty = null,
    strokeColor = null,
    strokeColorProperty = null
  } = iconData

  const isValidIconName = (name: string): name is keyof typeof Icons => {
    return name in Icons
  }

  const getColorValue = (colorName: string | null, shade: number | null) => {
    if (colorName === 'transparent') {
      return 'transparent'
    }
    if (!colorName || shade === null || shade === undefined) {
      return null
    }

    // colorName이 CarbonColors에 있는지 확인
    const colorShades = (CarbonColors as any)[colorName as keyof typeof CarbonColors]
    if (!colorShades) {
      return null
    }

    // shade가 colorShades에 있는지 확인
    return (colorShades as any)[shade] || null
  }

  const renderIcon = () => {
    if (isSvg) {
      const sizeStyle = `${width !== undefined ? `width: ${width}px;` : ''} ${
        height !== undefined ? `height: ${height}px;` : ''
      }`
      const svgDataWithStyle = imageString.replace(/<svg/, `<svg  style="${sizeStyle}"`)
      return (
        <div
          className="carbon-svg-icon-wrapper"
          dangerouslySetInnerHTML={{ __html: svgDataWithStyle }}
          onClick={(e) => {
            if (onClick) {
              e.stopPropagation()
              onClick()
            }
          }}
        />
      )
    }
    if (!isValidIconName(imageString)) {
      // eslint-disable-next-line no-console
      console.log('CBIconComponent error : icon name is not valid : ', imageString)
      return null
    }

    const colorValue = getColorValue(fillColor, fillColorProperty)
    const strokeColorValue = getColorValue(strokeColor, strokeColorProperty)
    const style = {
      ...(colorValue ? { fill: colorValue } : {}),
      ...(strokeColorValue ? { stroke: strokeColorValue } : {})
    }
    // eslint-disable-next-line import/namespace
    const IconComponent = Icons[imageString]
    return (
      <IconComponent
        className={classNames('carbon-icon-wrapper', {
          'carbon-icon-wrapper-clickable': onClick !== undefined
        })}
        height={height || undefined}
        style={style}
        width={width || undefined}
        onClick={(e) => {
          if (onClick) {
            e.stopPropagation()
            onClick()
          }
        }}
      />
    )
  }

  return <Tooltip {...tooltipProp}>{renderIcon()}</Tooltip>
}

export default CarbonIconWrapperComponent
